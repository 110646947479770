import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'app-open-tickets',
  templateUrl: './open-tickets.component.html',
  styleUrls: ['./open-tickets.component.scss']
})
export class OpenTicketsComponent implements OnInit {

  data: any  = [];
  constructor(private baseService: BaseService) {
    baseService.post("order-device/false", {from_date: '1-1-2000', to_date: '12-31-2100'}, true).subscribe((data) => {
      this.data = data;
    })
  }

  ngOnInit() {
  }

}
