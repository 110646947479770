import { Component, OnInit, NgZone } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { OrderDevice, Order, Service, User, Note, Customer } from '../../classes/classes';
import { Util } from '../../util/util';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  top = '0px';
  left = '0px';
  users: User[] = [];
  rightMenuShowed = false;
  isEditing = false;
  order: Order = new Order();
  order_device: OrderDevice = new OrderDevice();
  canChange: boolean = false;
  addnote: boolean = false;
  newNote: string = '';
  tempOD: OrderDevice = new OrderDevice();

  services = null;

  totalServices: number = 0;
  logs: any[] = [];
  notes: any[] = [];
  current: number = 0;


  constructor(public baseService: BaseService, private router: Router,  private activatedRoute: ActivatedRoute, private ngZone: NgZone, public util: Util) {


    baseService.get("user", true).subscribe((data: any) => {
      this.users = data;
      console.log(data);
    });


    let id:number = Number(activatedRoute.snapshot.paramMap.get('order_device_id'));
    baseService.get("order/order-device/" + id, true).subscribe((data: Order) => {
      this.order = data;

      this.order_device = this.order.order_devices.find(x => x.id == id) || new OrderDevice();

        this.order_device.services.forEach(s => {
        this.totalServices += Number(s.price);
      });




    });
  }


  logout(){
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  closeW(){
    this.router.navigateByUrl('/open-tickets')
  }

  nextPage(){
    this.router.navigateByUrl('/receipt/' + this.order_device.id)
  }

  ngOnInit() {


    window.addEventListener('keyup',(evt)=>{
      if(evt.key == 'Escape')
      {
        // console.log('esc');
        if(this.isEditing == true)
          this.cancel();
        else if(this.addnote == true)
          this.cancelNote();
        else if (this.services)
        {
          this.services = null;
        }
      }
    })


this.rightMenu() ;



  }

  rightMenu() {
    document.addEventListener('click', (evt) => {
      this.rightMenuShowed = false;
    })
    let main = document.getElementById('inputs');

    console.log(main);

    function onLongPress(element, callback) {
      let timer;

      element.addEventListener('touchstart', (evt) => {
        timer = setTimeout(() => {
          timer = null;
          callback(evt);
        }, 500);
      });

      function cancel() {
        clearTimeout(timer);
      }

      element.addEventListener('touchend', cancel);
      element.addEventListener('touchmove', cancel);
    }

    main.addEventListener('contextmenu', (evt) => {
      evt.preventDefault();
      if (this.isEditing || this.order_device.completed)
        return;
      console.log(evt);
      // console.log(this.top);

      let y = window.scrollY;
      console.log(y);
      this.top = (y + evt.clientY - 10)  + 'px';
      this.left = evt.clientX + 'px';
      console.log(this.isEditing);

      // console.log(main.style);
      this.tempOD = JSON.clone(this.order_device);
      this.rightMenuShowed = true;

      // OrderDetailComponent.this. rightMenuShowed = true;

    });

    onLongPress(main, (evt) => {
      if(this.isEditing || this.order_device.completed)
        return;
      Swal.fire({
        title: 'Do you want to edit?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.value) {
          this.isEditing = true;

        }
      })

    })

  }

  changeView(evt, pos) {

    let ulnav = document.getElementById("ulnav") as HTMLDivElement;
    ulnav.childNodes.forEach((x: HTMLElement) => {
      x.classList.remove("active");
    });
    evt.target.classList.add('active');
    let div = document.getElementById("allviews") as HTMLDivElement;
    let div2 = document.getElementById("allviews1") as HTMLDivElement;

    let i = 0;

    if (pos != 2)
      (div2.childNodes[0] as HTMLDivElement).classList.add("hide");
    else
       (div2.childNodes[0] as HTMLDivElement).classList.remove("hide");

    if (pos != 3)
    (div2.childNodes[1] as HTMLDivElement).classList.add("hide");
    else
    (div2.childNodes[1] as HTMLDivElement).classList.remove("hide");

    div.childNodes.forEach((x: HTMLElement) => {
      if (pos == 1 && i < 3)
      {
        x.classList.remove("hide");
      }
      else if (pos == 2 && i == 3)
        (div2.childNodes[0] as HTMLDivElement).classList.remove("hide");
      else  if (pos == 3 && i == 3)
        (div2.childNodes[1] as HTMLDivElement).classList.remove("hide");
      else if (i != 3)
        x.classList.add("hide");
      i++;
    })

    console.log(div.childNodes.length);
  }

  selectOrder(id) {
    if (this.isEditing)
      return;
    this.ngZone.run(() => {
      this.order_device = this.order.order_devices.find(x => x.id == id);
      this.totalServices = this.order_device.services.sumBy(x => x.price);
      // let x1 = this.order.order_devices;
      // let x = x1.find(c => c.id == id);
      // this.order_device = x;
      // this.totalServices = 0;
      // this.order_device.services.forEach(s => {
      //   this.totalServices += Number(s.price);
      // });
      // this.router.navigateByUrl('/order-detail/' + id);
    })

  }

  cancelNote(){
    this.addnote = false;
    this.newNote = '';
  }

  async saveNote(){
    try {
      let temp = await this.baseService.post1<OrderDevice>('order-device/note', {order_device: JSON.stringify(this.order_device), message: this.newNote}, true);
    this.order_device.note = temp.note;
    console.log(this.newNote);
    console.log(this.order_device.note);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The note has been added',
      showConfirmButton: false,
      timer: 1500
    });
    this.addnote = false;
    this.newNote= "";

    } catch (ex) {
      console.log(ex);
    }
  }

  async save() {


    try {

      let temp = await this.baseService.put<OrderDevice>('order-device', {order_device: JSON.stringify(this.order_device)}, true);
      this.order_device.log = temp.log;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The order detail has been edited',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (ex) {
      console.log(ex);
    }
    this.isEditing = false;



  }

  updatingServices = false;

  async updateServices() {
    try {
      this.updatingServices = true;
      let temp = await this.baseService.put<OrderDevice>('services/' + this.order_device.id, {services: JSON.stringify(this.services.map(x => new Service(x)))}, true);
      this.order_device.services = temp.services;
      this.order_device.log = temp.log;

      this.totalServices = this.order_device.services.sumBy(x => x.price);
      this.updatingServices = false;
      this.services = null;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The order detail has been edited',
        showConfirmButton: false,
        timer: 1500
      });

    }
    catch (ex) {
      this.updatingServices = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error',
        showConfirmButton: false,
        text: ex.message || 'Something went wrong',
        timer: 1500
      });
    }
  }

  cancel() {
    this.isEditing = false;
    this.order_device.device.type= this.tempOD.device.type;
    this.order_device.device.model.name= this.tempOD.device.model.name;
    this.order_device.device.model.brand.name = this.tempOD.device.model.brand.name;
    this.order_device.device.color = this.tempOD.device.color;
    this.order_device.device.serial = this.tempOD.device.serial;
    this.order_device.device.password = this.tempOD.device.password;
    this.order_device.accessorie = this.tempOD.accessorie;

    this.order_device = JSON.parse(JSON.stringify(this.order.order_devices.find(x => x.id == this.order_device.id) || new OrderDevice()));
    console.log(this.order_device.services);
  }

  async userChanged(evt) {
    // if(this.order_device.status != 'UNASSIGNED')
    //   this.canChange = false;
    // if(this.order_device.status == 'UNASSIGNED' )
    // {
      // this.canChange = true;
      let z = evt.target.value as number;
      console.log(z);
      // console.log();
      let user = this.users.find(x => x.id == z);

      if (user)
      {
        let s = this.order_device.status;
        this.order_device.status = s == 'UNASSIGNED' ? 'IN PROGRESS' : s;
        try {
          this.order_device.user = user;
          let temp = await this.baseService.put<OrderDevice>('order-device/user', {order_device: JSON.stringify(this.order_device)}, true);
          this.order_device.log = temp.log;
          this.order_device.status = temp.status;
          if (temp.user.id == 0)
            temp.user.id = null;
          this.order_device.user = temp.user;
        } catch (ex) {
          if (s == 'UNASSIGNED')
            this.order_device.status = s;
          console.log(ex);
        }
      }
    //}
//
  }

  orderForm(all:boolean){
    let id= all? this.order.order_devices.map(x=>x.id).join('&'): this.order_device.id;
    this.router.navigateByUrl(`/order-form/${id}`);
  }

  confirmMove() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to move this order from archive?, this also will delete the receipt',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result: any) => {
      // console.log(result);
      if (result.value)
        this.statusChanged({target: {value: 'COMPLETED'}});
    });
  }

  async statusChanged(evt)
  {
    let status  = evt.target.value;
    let u = this.order_device.user;

    if (status == 'UNASSIGNED')
    {
      let u = new User();
      u.id = null;
      this.order_device.user = u;
    }
    try {
      // let od = this.order_device;
      this.order_device.status = status;
      let temp = await this.baseService.put<OrderDevice>('order-device/status', {order_device: JSON.stringify(this.order_device)}, true);
      this.order_device.log = temp.log;
      this.order_device.status = temp.status;
      this.order_device.completed = temp.completed;
      if (temp.user.id == 0)
        temp.user.id = null;
      // console.log(temp.user);
      this.order_device.user = temp.user;
    } catch (ex) {
      if (status == 'UNASSIGNED')
        this.order_device.user = u;
      console.log(ex);
    }
  }

  updateName(evt, charge) {
    evt.target.value = evt.target.value.trim();

  }

  updateCustomer(cust){
    this.baseService.post('customer', {customer: JSON.stringify(cust) }, true).subscribe((data:Customer) => {

    })

  }

  capitilaze(evt, charge) {
    // let text = charge.name;
    // text = text.trim();
    // console.log(text);

    // let text1 = '';
    // for (let i = 0; i < text.length; i++)
    // {
    //   if (i == 0)
    //     text1 = text.substring(0, 1).toUpperCase();
    //   else if (text.substring(i - 1, i) == ' ')
    //   {
    //     if (text.substring(i, i + 1) == ' ') i++;
    //     else text1 += text.substring(i, i + 1).toUpperCase();
    //   }
    //   else
    //   {
    //     text1 += text.substring(i, i + 1).toLowerCase();
    //   }
    // }
    charge.name = charge.name;
  }

  removeService(evt, pos)
  {
    let service = evt.target.parentNode.parentNode as HTMLDivElement;
    service.style.animation = 'fadeOut .3s forwards';
    setTimeout(() => {
      // this.order.order_devices[this.current].services.splice(pos, 1);
      this.services.splice(pos, 1);
    }, 300);
    console.log(pos);
  }

  enterServicePressed(evt, service: Service)
  {
    if (service.name.trim().length == 0)
      return;

    this.baseService.post('service/price', {model_id: this.order.order_devices[this.current].device.model.id, service_name: service.name}, true)
        .subscribe((data: any) => {
              service.price = data.price == "0" ? data.price + ".00" : data.price.includes('.') ?  data.price : data.price + ".00";
            },
            err => console.log(err));
  }



  editServices() {
    this.services = JSON.parse(JSON.stringify(this.order_device.services));
  }



  addService() {
    this.services.push(new Service());
  }


  isValid() {
    if (!this.services) return false;
      return !this.services.some(x => x.name.trim() == '');
  }
}
