<div>
    <div class="od-container">
        <div class="row" style="margin-top: 30px;">
            <div class="column-top">
                <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
            </div>
            <div class="column-top">
                <div class="row mb-80">
                    <div class="column w-6">
                        <!-- <h4 class="mb-23">{{d | date:('medium')}}</h4> -->
                        <h2 class="mb-26">#123456 (Order: #1000)</h2>
                        <p>Taken by: <strong>___________</strong></p>
                    </div>
                    <div class="column w-6 text-right">
                        <h3 class="mb-20">LAPC Networking Inc.</h3>
                        <p class="mb-26">3609 W, Magnolia Blvd.<br>Burbank, CA 91505</p>
                        <p>(818) 478-2222 | www.lapcnetworking.com</p>
                    </div>
                </div>
                <div class="row mb-40">
                    <div class="column w-6">
                        <p class="title mb-17">CUSTOMER</p>
                        <h3 class="mb-20">CUSTOMER NAME HERE</h3>
                        <p class="mb-26">1000 Main St.<br>Los angeles, CA 90000</p>
                        <p>(000) 000-0000 | abc@xyz.com</p>
                    </div>
                    <div class="column w-6">
                        <p class="title mb-12">NOTES</p>
                        <div class="notes-container">
                            <p>Add any notes here...</p>
                        </div>
                    </div>
                </div>
                <div class="row mb-80">
                    <div class="column w-6">
                        <p class="title mb-17">DEVICE</p>
                        <h3 class="mb-20">DEVICE NAME HERE</h3>
                        <p>Color</p>
                        <p>Serial: XXXXXXXXXX</p>
                        <p>Accessories: Laptop Charger</p>
                    </div>
                </div>
                <div class="row table mb-12">
                    <div class="column w-12">
                        <h3 class="terms w-12">SERVICE DESCRIPTION</h3>
                    </div>
                    <div class="column w-6">
                        <h3 class="terms w-12">TOTAL </h3>
                    </div>
                </div>
                <hr class="mb-12">
                <div class="row table mb-80">
                    <div class="column w-12">
                        <p class="w-12">Service Name</p>
                    </div>
                    <div class="column w-6">
                        <p class="w-12">TOTAL </p>
                    </div>
                </div>

                <div class="mb-12 scroll-2">
                    <h3 class="mb-12 terms">TERMS AND CONDITIONS</h3>
                    <textarea [(ngModel)]="terms_front.value" style="overflow: hidden; font-family: 'Open Sans', sans-serif;" class="editable" name="" id="" cols="150" rows="13"></textarea>
                </div>

                <div class="mb-12">
                    <h3 class="terms" style="margin-bottom: 40px;">BY SIGNING BELOW, I AGREE TO ALL THE TERMS AND CONDITIONS DESCRIBED ABOVE AND ON THE BACK OF THIS FORM.</h3>
                </div>

                <div class="row table mb-40">
                    <div class="column w-12">
                        <hr style="width: 70%; margin-bottom: 6px;">
                        <p class="signature w-12">NAME</p>
                    </div>
                    <div class="column w-6">
                        <hr class="w-6 mb-6">
                        <p class="signature w-12">TOTAL </p>
                    </div>
                </div>
                <div>
                    <hr class="w-6 mb-6">
                    <p class="signature w-12 mb-114">Signature</p>
                </div>

            </div>
        </div>
    </div>

    <div class="od-container">

        <div>
            <div class="row" style="margin-top: 30px;">
                <div class="column-top">
                    <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
                </div>
                <div class="column-top">
                    <h3 class="terms mb-12">TERMS AND CONDITIONS (CONTINUED)</h3>
                    <textarea [(ngModel)]="terms_back.value" style="overflow: hidden; font-family: 'Open Sans', sans-serif;" class="editable" name="" id="" cols="150" rows="80"></textarea>
                </div>

            </div>
        </div>
    </div>

</div>

<div class="btn-container floating-buttons">
    <button class='btn btn-black btn-big' routerLink="/open-tickets">Back</button>
    <button class='btn btn-black btn-big' (click)="updateTerms()" [disabled]='!isValid()'>Save Changes</button>
</div>