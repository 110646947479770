<div class="main-container" id="main-container" [ngStyle]="{'display': order_device.id == -1 ? 'none' : 'block'}">
    <div class="header">
        <div class="top container container-1">
            <button class="btn btn-clear gray no-padding" (click)='closeW()'>
                    <i class="fas fa-chevron-left"></i>
                    Open Tickets
            </button>

            <img class="no-close" src="../../../assets/imgs/close.png" alt="" (click)='closeW()'>
        </div>

        <div class="bottom container container-1 no-padding">
            <div class="row">
                <p>Device</p>
                <div style="display: flex; justify-content: flex-start;">
                    <p class="fs-18 bold" style="margin-right: 10px;">#{{order_device.id}}
                    </p>
                    <select class="btn btn-0br btn-small" [(ngModel)]="order_device.status" [ngStyle]="{'background': util.getColorByStatus(order_device.status)}" (change)='statusChanged($event)' [disabled]='isEditing || order_device.completed'>
                        <option *ngIf="!order_device.completed" [selected]="order_device.status == 'IN PROGRESS'" value="IN PROGRESS" [disabled]='order_device.user.id == null'>IN PROGRESS</option>
                        <option *ngIf="!order_device.completed" [selected]="order_device.status == 'UNASSIGNED'" value="UNASSIGNED" [disabled]='order_device.user.id == null' >UNASSIGNED</option>
                        <option *ngIf="!order_device.completed" [selected]="order_device.status == 'COMPLETED'"value="COMPLETED" [disabled]='order_device.user.id == null'>COMPLETED</option>
                        <option *ngIf="order_device.completed"  [selected]="order_device.status == 'ARCHIVED'"value="ARCHIVED" [disabled]='order_device.user.id == null'>ARCHIVED</option>
                    </select>
                </div>

            </div>
            <div class="row no-mobile">
                <p>Technician</p>
                <select class="btn btn-0br btn-small" style="height: 27px; font-size: 10px !important;" (change)='userChanged($event)' id="tech" [(ngModel)]='order_device.user.id' [disabled]="isEditing || order_device.completed" placeholder='ad'>
                    <!-- <option style="color: #999;" selected='true' value="" *ngIf='order_device.user.id == null || order_device.user.id == -1'>Select one</option> -->
                    <option [value]="null" *ngIf='order_device.status == "UNASSIGNED"'>Select One</option>
                    <option *ngFor="let user of users" [value] = "user.id">{{user.name}}</option>
                </select>

            </div>
            <div class="row no-mobile">
                <p>Due Date</p>
                <p>{{order_device.time_due | date :'shortDate'}}</p>
            </div>
            <div class="row no-mobile">
                <p>Due Time</p>
                <p>{{order_device.time_due | date: 'shortTime'}}</p>
            </div>
        </div>
    </div>

    <nav class="nav-mobile">
        <ul class="b" id='ulnav'>
            <a class="active" (click)='changeView($event, 1)'>Key Info</a>
            <a (click)='changeView($event, 2)'>Logs</a>
            <a (click)='changeView($event, 3)'>Notes</a>
        </ul>
    </nav>

    <div class="customer-device-container container container-1" id="allviews">

        <div class="web-view no-web anim">
            <div>
                <p>Technician</p>

                <select class="btn btn-0br btn-small" style="height: 27px; font-size: 10px !important;" (change)='userChanged($event)' id="tech" [(ngModel)]='order_device.user.id' [disabled]="isEditing || order_device.completed" placeholder='ad'>
                    <!-- <option style="color: #999;" selected='true' value="" *ngIf='order_device.user.id == null || order_device.user.id == -1'>Select one</option> -->
                    <option [value]="null" *ngIf='order_device.status == "UNASSIGNED"'>Select One</option>
                    <option *ngFor="let user of users" [value] = "user.id">{{user.name}}</option>
                </select>
            </div>

            <div class="view container-1">
                <div class="space-between">
                    <p>Due Date</p>
                    <p>{{order_device.time_due | date :'shortDate'}}</p>
                </div>
                <div>
                    <p>Due Time</p>
                    <p>{{order_device.time_due | date: 'shortTime'}}</p>
                </div>
            </div>
        </div>

        <div class="web-container anim">
            <div class="customer-box container-1" style="width: 50%;">
                <div class="customer-box-header">
                    <p>Customer</p>
                </div>
                <input class="input-charge" [(ngModel)]='order.customer.name' (input)='capitilaze($event, order.customer)' (blur)='updateName($event, order.customer); updateCustomer(order.customer)' type="text" style="font-weight: bold;">
                    <!-- <p>{{order.customer.name}}</p> -->
                <p>{{order.customer.phone1}}</p>
                <p>{{order.customer.email}}</p>
                <p [ngStyle]="{'color' : order.customer.street == '' ? 'red' : ''}">{{order.customer.street == '' ? 'No address' : order.customer.street}}</p>
                <p *ngIf="order.customer.city != ''">{{order.customer.city != '' ? order.customer.city + "," : ""}} {{order.customer.state}} {{order.customer.zip}}</p>
            </div>

            <div class="customer-box container-1 space1" style="width: 50%;">
                <div class="customer-box-header together" style="align-items: baseline;">
                    <p>Device</p>
                    <button class="btn btn-outline btn-small btn-bold" *ngIf="!isEditing">{{order_device.device.type}}</button>

                </div>
                <div class="dev" id="inputs">
                    <div *ngIf="isEditing">
                        <label for="type">Type: </label> <input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.type" [readOnly]="!isEditing"><br><br>
                        <label for="name">Name: </label> <input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.model.name" [readOnly]="!isEditing"><br><br>
                        <label for="brand">Brand: </label> <input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.model.brand.name" [readOnly]="!isEditing"><br><br>
                    </div>
                    <label for="color">Color: </label><input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.color" [readOnly]="!isEditing"><br><br>
                    <label for="serial">Serial: </label><input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.serial" [readOnly]="!isEditing"><br><br>
                    <label for="password">Password: </label><input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.device.password" [readOnly]="!isEditing"><br><br>
                    <label for="accessories">Accessories: </label><input type="text" [ngClass]="isEditing? 'edit' : ''" [(ngModel)]="order_device.accessorie" [readOnly]="!isEditing"><br><br>
                    <div *ngIf="isEditing" class="bott" style="padding: 0%;">
                        <div style="margin-top: 10px;">
                            <button class="btn btn-outline btn-small btn-bold" (click)="save()">Save</button>
                            <button class="btn btn-outline btn-small btn-red" (click)="cancel()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="web-container anim">
            <div class="customer-box container-1" style="width: 50%;">
                <p>Device(s) in Order #{{order.id}}</p>

                <div style="min-height: 200px; max-height: 200px; overflow: scroll;">
                    <div class="devices" *ngFor="let od of order.order_devices; let i = index" [ngClass]="{'active': od.id == order_device.id, 'disabled':  isEditing}" (click)='selectOrder(od.id)'>
                        <ng-container>
                            <p style="display: flex; justify-content: space-between; width: 90%;">#{{od.id}} <span>{{i + 1}} of {{order.order_devices.length}}</span></p>
                            <p>{{od.device.model.brand.name}} {{od.device.model.name}} </p>
                            <button class="btn btn-outline btn-14br btn-small btn-bold">{{od.device.type}}</button> <button class="btn btn-0br btn-small" [ngStyle]="{'background': util.getColorByStatus(od.status)}">{{od.status}}</button>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="customer-box container-1 space" style="width: 40%; padding: 0%;">
                <div style="display: flex; justify-content: space-between; align-items: center">
                    <p>Services</p>
                    <button (click)="editServices()" [disabled]="isEditing" class="btn btn-outline btn-small btn-bold" >Edit</button>
                </div>
                <div>
                    <ng-container *ngFor="let service of order_device.services">
                        <p style="font-weight: bold; display: flex; width: 100%; justify-content: space-between;"> {{service.name}}

                            <a style="font-weight: bold; font-size: 14px; text-align: right;">{{service.price | currency}}</a></p>
                        <hr/>
                    </ng-container>

                    <p style="font-size: 14px; text-align: right;">Total <a style="font-size: 14px; font-weight: bold;">{{totalServices | currency}}</a></p>
                </div>
                <div>
                    <button *ngIf="!addnote && !order_device.completed" (click)="addnote = !addnote" [disabled]='isEditing || order_device.completed' class="btn btn-clear no-padding bold hide" style="font-size: 16px;margin-bottom: 10px;">Add New Note <i class="fas fa-plus red"></i></button>
                    <div *ngIf="addnote">
                        <label style="padding-top: 20px !important; padding-right: 20px;">Note</label>
                        <textarea [(ngModel)]="newNote" class="text-notes"></textarea>
                        <div class="btn-notes">
                            <button style="margin-right: 6px;" class="btn btn-outline btn-small btn-bold" (click)="saveNote()" [disabled]="!newNote">Save</button>
                            <button class="btn btn-outline btn-small btn-red" (click)="cancelNote()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="web-container" id="allviews1">
            <div class="anim hide" style="width: 50%;">
                <p class="log">Log</p>
                <div class="scroll">
                    <ng-container *ngFor="let log of order_device.log; let i= index">
                        <p class="scroll-items">{{log.time | date: 'short'}}</p>
                        <p class="bold scroll-items">{{log.message}}</p>
                        <hr *ngIf="i != order_device.log.length - 1">
                    </ng-container>
                </div>
            </div>

            <div class="anim hide" style="width: 50%;">
                <div class="no-web">
                    <button *ngIf="!addnote && !order_device.completed" (click)="addnote = !addnote" [disabled]='isEditing || order_device.completed' class="btn btn-clear no-padding bold" style="font-size: 16px;margin-bottom: 10px;">Add New Note <i class="fas fa-plus red"></i></button>
                    <div *ngIf="addnote">
                        <label style="padding-top: 20px !important; padding-right: 20px;">Note</label>
                        <textarea [(ngModel)]="newNote" class="text-notes"></textarea>
                        <div class="btn-notes">
                            <button style="margin-right: 6px;" class="btn btn-outline btn-small btn-bold" (click)="saveNote()" [disabled]="!newNote">Save</button>
                            <button class="btn btn-outline btn-small btn-red" (click)="cancelNote()">Cancel</button>
                        </div>
                    </div>
                </div>
                <p class="notes">Notes from Technician </p>
                <div class="scroll">
                    <ng-container *ngFor="let note of order_device.note; let i=index">
                        <p class="scroll-items">{{note.time | date: 'short'}}</p>
                        <p class="scroll-items">{{note.message}}</p>
                        <p class="note-name scroll-items">Made by {{note.name}}</p>
                        <hr *ngIf="i != order_device.note.length - 1">
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="container btn-container mobile-button">
            <button class="btn btn-big" style="margin-top: 26px; margin-bottom: 40px; margin-right: 12px;" (click)="orderForm(false)" [disabled]='isEditing'>REPRINT</button>
            <button class="btn btn-big" style="margin-top: 26px; margin-bottom: 40px; margin-right: 12px;" (click)="orderForm(true)" [disabled]='isEditing' *ngIf="order.order_devices.length > 1">REPRINT ALL</button>
            <button class="btn btn-big" style="margin-top: 26px; margin-bottom: 40px;" (click)="nextPage()" [disabled]='isEditing' *ngIf='order_device.completed || order_device.status == "COMPLETED"'>{{order_device.completed ? 'SEE RECEIPT' : 'ISSUE RECEIPT'}}</button>
            <button class="btn btn-big" style="margin-top: 26px; background: #C50532; margin-bottom: 40px; margin-left: 12px" (click)="confirmMove()" *ngIf='order_device.completed'>MOVE TO COMPLETED</button>
        </div>

    </div>

</div>

<div class="menu" *ngIf='rightMenuShowed' [ngStyle]="{'top': top, 'left': left}">
    <ul>
        <li><a (click)="isEditing = true">Edit <i class="fas fa-edit"></i></a></li>
    </ul>

</div>

<div style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, .5); z-index: 2" *ngIf="services">
    <div style="display: flex; flex-direction: column; flex: 1; overflow:auto; position:absolute; width: 90%; max-width: 800px; height: 80vh; background: #FFF; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 8px; padding: 16px;">
        <div style=" margin-bottom: 16px; display: flex; justify-content: space-between">
            <h4 style="font-size: 14px;">Edit Services</h4>
            <h4 *ngIf="!updatingServices" (click)="addService()" style="font-size: 14px; cursor: pointer; user-select: none;"><i class="fas fa-plus red"></i> Add New Service</h4>
        </div>

        <div *ngIf="updatingServices">
            <img style="display: block; margin: 0 auto; width: 32px; height: 32px;" src="https://www.costcoauto.com/save/images/ajax-loading.gif" alt="">
            <h4 style="font-size: 14px; text-align: center; margin-top: 8px;">Updating...</h4>
        </div>
        <div style="display: flex; flex-direction: column; flex: 1; overflow:auto;" *ngIf="!updatingServices">
            <div class="services-container" id="services">
                <div class="service" *ngFor='let service of services; let i = index;'>
                    <p>{{i + 1}}.)</p>
                    <input type="text" (input)='capitilaze($event, service)' [(ngModel)]='service.name' placeholder="Service Name" (keyup.enter)='enterServicePressed($event, service)'>
                    <input type="text" [(ngModel)]='service.price' placeholder="Price" mask="separator.2" thousandSeparator=",">
                    <!-- <p class="font-12">$100.00 </p> -->
                    <button (click)='removeService($event, i)' class="btn btn-clear no-padding"><i class="fas fa-times"></i></button>

                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 16px;" *ngIf="!updatingServices">
            <button (click)="services = null" class="btn btn-red" style="width: calc(50% - .5rem)">Cancel</button>
            <button [disabled]="!isValid()" (click)="updateServices()" class="btn btn-black" style="width: calc(50% - .5rem)">Save</button>
        </div>
    </div>
</div>
