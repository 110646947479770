import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer, User} from "@classes/classes";
import {BaseService} from "../../services/base.service";
import {Router} from "@angular/router";
import * as XLSX from "xlsx";
import * as momentTZ from "moment-timezone";
import Swal from "sweetalert2";
import * as moment from "moment";

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {


  // selected: any;
  // firstTime: boolean = true;
  // reselectFilter1: boolean = false;
  private input: HTMLInputElement;
  public customers: User[] = [];
  private original = [];
  public states: any[] = ["AK", "AL", "AR", "AZ",
    "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD",
    "ME", "MI", "MN", "MO", 'MS', "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI",
    "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"];
  public searchTerm: string = '';
  sheetShowed: boolean = false;
  html = '<h2>Hello world</h2>';
  selectedCustomer: User = null;
  selectedCustomers: User[] = [];
  allSelected:boolean= false;
  // pos : number = 0;
  // allTypeSelected: boolean = true;
  // @ViewChildren('table') things: QueryList<any>;
  // @ViewChildren('filter') things1: QueryList<any>;
  // original: any = [];
  sortBy = {name: '', asc: false};
  selectedOrderDevices: Customer[] = [];
  @Input() public isSearching: boolean = false;
  @Output() public customerSelected: EventEmitter<Customer> = new EventEmitter();

  constructor(private cdRef: ChangeDetectorRef, public baseService:BaseService, private router: Router) {
    // console.log(new Date().toTimeString());
    if (baseService.USER.level < 9)
      this.router.navigateByUrl('/open-tickets').then();
    else
    {
      this.baseService.get(`employee`, true).subscribe((data:User[]) => {
        this.customers = data;
        // console.log(new Date().toTimeString());
        this.original = data;

        if (this.baseService.USER.level < 9)
          this.router.navigateByUrl('/open-tickets');
        // this.export();
        // console.log(data.filter(x => x.devices.length > 0).sort((y, z) => y.devices.length > z.devices.length ? 1 : -1));

        // console.log(new Date().toTimeString());
        // console.log(data);
      }, err => {

      });
    }

  }

  public export() {

    // this.sele
    let users = this.selectedCustomers.clone();

    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(users);

    let today= momentTZ(new Date()).tz('America/Los_Angeles').format('MM_DD_YYYY_hh_mm_ss_A');
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Employees'); // add the worksheet to the book
    XLSX.writeFile(workBook, `Employees_${today}.xlsx`); // initiate a file download in browser
  }

  selectAll(){
    if(this.allSelected){
      this.selectedCustomers = this.selectedCustomers.concat(this.customers.filter(x => this.selectedCustomers.findIndex(y => y.id == x.id) == -1));
    }
    else
      this.selectedCustomers = [];
  }

  // Inputs

  public customer: User = new User();

  updateCustomer (customer){
    this.customer = customer;
    this.sheetShowed = true;
  }

  code = '';
  confirmCode = '';

  submit(form) {
    if (!this.isValid()){
      return;
    }
    this.baseService.post('employee', {user: JSON.stringify(this.customer) }, true).subscribe((data:User) => {
      // alert('customer inserted with id:' + data.id);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User has been created.',
        showConfirmButton: false,
        timer: 1500
      });
      this.sheetShowed = false;

      let temp = new User(data);
      this.customer = new User();

      this.original.push(temp);
      this.searchCustomer({target: {value: this.searchTerm}});

    }, err => {
      alert(err);
          console.log(err);
    });
  }

  toogleSelected(cust: User){
    let index = this.selectedCustomers.findIndex(x=> x.id == cust.id);
    console.log(index)
    if(index == -1)
      this.selectedCustomers.push(cust);
    else
      this.selectedCustomers.splice(index, 1);


    // if (this.selectedCustomers.length == this.customers.length)
      this.allSelected = this.selectedCustomers.length == this.customers.length;
  }

  isCustSelected(cust: Customer){
    return this.selectedCustomers.findIndex(x=> x.id == cust.id) != -1;
  }

  isValid (): boolean {
    return this.customer.name.trim() != '' && this.customer.level >= 0 && this.code == this.confirmCode && this.code.trim().length > 0;
  }

  open() {
    this.sheetShowed = true;
    this.customer = new User();
    this.confirmCode = '';
    this.code = '';
    // console.log("HERE");
  }
  doSomething(e) {
    // console.log(e);
  }

  selectCust(customer) {
    // console.log(customer);
    if (!customer)
      return;
    if (this.isSearching)
    {
      this.customerSelected.emit(customer);

    }
    else
    {
      localStorage.setItem("customer", JSON.stringify(customer));
      this.router.navigateByUrl("/new-order");
    }
  }


  deleteCustomer(customer: User)
  {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-blue btn-0br',
        cancelButton: 'btn btn-red btn-0br mr-20'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: `Are you sure?`,
      html: `<strong>Delete user #${customer.id} - ${customer.name}</strong><br>You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.baseService.delete('employee/' + customer.id, true).subscribe(data => {
          const index = this.original.findIndex(x => x.id == customer.id);
          this.original.splice(index, 1);
          this.searchCustomer({target: {value: this.searchTerm}});
          swalWithBootstrapButtons.fire(
              'Deleted!',
              'User has been deleted.',
              'success'
          );
        }, err => {
          alert('Error: ' + err);
        });
      }
    });

  }

  close(): void {
    let sheet = document.getElementById("modal-customer");
    sheet.style.animation = 'toUp 1s forwards';
    // console.log("READY");
    setTimeout(() => {
      this.sheetShowed = false;
    }, 800);
  }

  customerModal() {
    Swal.fire({
      title: '<strong>Insert new employee</u></strong>',
      html:
          `
        <div class="fields">
            <div class="field">

                <label>USERNAME</label> <br>
                <input type="text" name="username" placeholder="Type Username" ngModel>
            </div>
            <div class="field">
                <label>PASSWORD</label> <br>
                <input type="password" name="password" placeholder="Type Password" ngModel>
            </div>

            
        </div>
        `,
      showCloseButton: true,
      showCancelButton: false,
    })
  }

  ngOnInit() {

    this.input = document.getElementById("search-input") as HTMLInputElement;

  }

  ngAfterViewInit() {}

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }


  getTotalItem(){

    let total : number = 0;
    this.items.forEach(i => {
      total += Number (i.count);
    })
    return total;
  }


  searchShown: boolean = true;
  items:any = [];


  getDiffTime(date) {
    let d1 = moment(date);
    let d2 = moment(new Date());
    let diff = d1.diff(d2, 'days');
    if (diff == 0)
      return "(today)";
    else if (diff > 0)
      return `(in ${diff} days)`
    else
      return `(${Math.abs(diff)} days ago)`
  }

  async search(evt)
  {
    const div = document.getElementById("search-b");

    div.classList.toggle("searching");
    this.searchShown = div.classList.contains("searching");
    this.searchTerm = this.searchShown ? this.searchTerm : '';
    if (!this.searchShown)
      this.customers = JSON.parse(JSON.stringify(this.original));
    else
      this.input.focus();


  }
  something() {
    // console.log("HERE");
  }

  searchCustomer(evt) {
    let term:string = evt.target.value;
    term = term.toLowerCase().trim();
    // console.log(term);
    if (term.length == 0)
      this.customers = JSON.parse(JSON.stringify(this.original));
    else
      this.customers = this.original.filter(x => x.name.toLowerCase().trim().indexOf(term) > -1 ||  x.level.toString().toLowerCase().trim().indexOf(term) > -1 || x.id.toString().toLowerCase().trim().indexOf(term) > -1);
    setTimeout(() => {
      if (this.allSelected)
      {

        this.selectedCustomers = [];
        this.allSelected = true;
        this.selectAll();
      }
      // this.allSelected = this.customers.filter(x => this.selectedCustomers.findIndex(y => y.id == x.id) != -1).length == this.customers.length;
    }, 1);
  }
  change1(evt) {
    let div = document.getElementById("filters-1");
    let buttons = div.querySelectorAll("button");

    buttons.forEach(x => {
      x.classList.add("btn-outline");
    });
    evt.target.classList.remove("btn-outline");
  }

  selectCustomer(user: User)
  {
    this.selectedCustomer = user;
    // console.log('customer', this.customerSelected);
  }

  sort(name: string)
  {
    // console.log('HERe');
    if (this.sortBy.name == name)
      this.sortBy.asc = !this.sortBy.asc;
    else
    {
      this.sortBy.name = name;
      this.sortBy.asc = true;
    }

    console.log(this.sortBy);

    let temp = this.customers.clone();
    this.customers = [];
    switch (this.sortBy.name)
    {
      case 'id':
        console.log('HERE');
        if (this.sortBy.asc)
          this.customers = temp.sort((x, y) => x.id > y.id ? 1 : -1);
        else
          this.customers = temp.sort((x, y) => x.id < y.id ? 1 : -1);
        break;
      case 'customer':
        if (this.sortBy.asc)
          this.customers = temp.sort((x, y) => x.name > y.name ? 1 : -1);
        else
          this.customers = temp.sort((x, y) => x.name < y.name ? 1 : -1);
        break;
    }
    // console.log(this.orders_device.map(x => x.id));

  }

  iconSide = () => this.sortBy.asc ? 'up' : 'down';

}
