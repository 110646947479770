import { Component, OnInit, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { delay } from 'q';
declare let $: any;

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss']
})

export class ComboboxComponent implements OnInit {

  listShow = false;
  showed: boolean = false;
  input: HTMLInputElement;
  buttons: HTMLButtonElement[];
  original: any[] = [];
  // property: string = '';
  showBorder: boolean = false;
  // data: any[] = [];
  // makes: string[] = ["make 1", "make 2", "make 3", "make 4", "make 51", "sds make12"];
  allowClick: boolean = true;
  // placeHolder: string = '';


  private _timeout: any;
  @HostListener('mousedown') onMouseDown() {
    this.allowClick = true;
      this._timeout = setTimeout(() => {
        // console.log("HERE");
        this.allowClick = false;
      }, 500);
      // console.log("HERE");
  }
  
  @HostListener('mouseup') async onMouseUp() {
      clearTimeout(this._timeout);
      // await delay(100);
  }
  
  @HostListener('mouseleave') async onMouseLeave() {
      clearTimeout(this._timeout);
      // await delay(100);
      // this.allowClick = true;
  }
  public _disabled: boolean = false;
  @Input() public placeholder: string = '';
  @Input() public data: any[] = [];
  @Input() public property: string = '';
  @Input() public canType = true;
  @Input() public hideIcons = false;
  @Input() public set  disabled(disabled: boolean)
  {
    this._disabled = disabled;
    if (disabled && this.input)
      this.input.value = '';
  }
  @Input() public div: any = null;
  @Input() public _id: string = null;
  @Input() public text: string = '';
  @Input() public ngModel1: string = '';
  @Input() public border = true;
  @Input() public defaultText: string = '';
  @Input() public set _data(d: any) {
    this.original = JSON.parse(JSON.stringify(d));
    this.data = d;
    if (!this.defaultText)
    {
      this.ngModel1 = '';
    }
    else
    {
      this.ngModel1 = this.defaultText;
      // console.log('this.text')
    }
    // console.log(this.defaultText);
  }
  @Input() public set value(value: string)
  {
    if (this.input){
      this.input.value = value;
      this.search({target: this.input});

    }
  }

  @Output() public dataChanged: EventEmitter<any> = new EventEmitter();



  @HostListener('document:click', ['$event'])
  public onClick(evt: MouseEvent) {
    if (this.allowClick)
    {
      let x = this.input == evt.target;
      if (!x)
      {
        this.showed = false;
        setTimeout(() => {
          this.showBorder = false;
          
        }, 200);
        // console.log("HERE x2");
      }
      // console.log(x);
    }
    this.allowClick = true;
  }

  search(evt) {
    let text: string = evt.target.value;
    // console.log("search");
    if (this.canType)
    {
      if (this.original.length == 0)
      this.original = JSON.parse(JSON.stringify(this.data));
    console.log(text);
    if (text.trim().length > 0)
    {
      if (this.property)
        this.data = this.original.filter(x => x[this.property].toLowerCase().indexOf(text.toLowerCase()) > -1);
      else
        this.data = this.original.filter(x => x.toLowerCase().indexOf(text.toLowerCase()) > -1);
    }
      
    else
    {
      this.data = JSON.parse(JSON.stringify(this.original));

      // console.log(this.data.length);
      
    }
    }
    
    // console.log("HERE");

    let r;
    if (this.property)
      r = this.data.filter(x => x[this.property].toLowerCase() == text.toLowerCase())[0];
    else
      r= this.data.filter(x => x.toLowerCase() == text.toLowerCase())[0];

      // console.log('DATA', r);
    if (r)
    {
      this.dataChanged.emit(r);
    }
    else
    {
      if (this.property)
      {
        let x: any = {};
        x[this.property] = this.input.value;
        x.id = -1;
        this.dataChanged.emit(x);

      }
      else
      {
        this.dataChanged.emit(this.input.value);
      }
      return;
    }

  
    // this.dataChanged.emit(r);
      // this.data = JSON.parse(JSON.stringify(this.original));
    // let original = ["make 1", "make 2", "make 3", "make 4", "make 51", "sds make12"];
    // console.log(s);
    // if (s.trim() != '')
    // {

    //   this.makes = original.filter(x => x.trim().toLowerCase().indexOf(s.trim().toLowerCase()) > -1);
    //   console.log(this.makes);
    // }
    // else
    //   this.makes = JSON.parse(JSON.stringify(original));

  }
  constructor(private dom: ElementRef) { }

  ngOnInit() {
  this.input = this.dom.nativeElement.getElementsByTagName("input")[0];
  // this.buttons = this.dom.nativeElement.getElementsByTagName("*");
  // console.log("HERE", this.buttons);
  
  }

  something(value) {
    if (this.allowClick)
    {
      if (this.canType)
      {

        this.input.value = this.property ? value[this.property] : value;
        // console.log(value);
        this.dataChanged.emit(value);
      }
      else
      {

        this.input.value = value['tax'] + '%'
        // console.log(value);
        console.log(value);
        this.dataChanged.emit(value);
      }
      // this.input.value = value;
      
      // this.ngModel1 = value[this.property];
      // console.log(this.ngModel1);
    }
    // this.allowClick = true;
  }

  async showList() {
    // document.body.scrollTop = this.input.scrollTop;
    // var toTop = document.getElementById("goto-topJS");
  
    // console.log(this.div);
    // let input = document.getElementById(this._id || "input").offsetTop;
    // console.log(input)
    // let scroll = this.input.offsetTop;
    // console.log(scroll);
    // this.div.animate({ scrollTop: this.input.scrollTop }, 600);

    await delay(1);
    // this.scrollToTop(10);
    this.search({target: this.input});
    this.listShow = true;
    this.showed = true;
    setTimeout(() => {
      this.showBorder = true;
      // this.input.value = 's';
    }, 100);
    // console.log("HERE");
  }


  // getAnimationTime() 
  // {
  //   // let time = this.makes.length > 3 ? '.25' : '.25'// + this.makes.length;
  //   console.log(time);
  //   return time;
  // }

  clear() {
    this.input.value = '';
    this.search({target: this.input});

  }

  s() {
    if (this.disabled)
      return;
    // this.showList();
    setTimeout(() => {
      
    this.input.focus();
    // console.log("HERE");
    }, 1);
  }

  move(evt) {
    if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
      evt.view.event.preventDefault();
  }
    // console.log('move');
    let buttons = this.dom.nativeElement.getElementsByTagName("button");
    // console.log(buttons[0]);
    buttons[0].focus();
  }
  move1(evt) {
    let buttons = this.dom.nativeElement.getElementsByTagName("button");
    // console.log(buttons[0]);
    let pos = -1;
    // console.log("HERE");
    for (let i = 0; i < buttons.length; i++)
    {
      if (buttons[i] == evt.target)
      {
        pos = i + 1;
        // console.log(pos);
        break;
      }
    }
    // console.log(pos);
    buttons[pos].focus();
    // buttons[0].focus();

  }

  getSize() {
    let x = Number((this.data.length > 5 ? 5 : this.data.length)) * 32;
    return x;
  }

  check(evt) {
    
    // this.dataChanged.emit(value);
  }

  enterPressed(evt) {

    return;

    let r;
    if (this.property)
      r = this.data.filter(x => x[this.property].trim().toLowerCase() == this.input.value.toLowerCase())[0];
    else
      r = this.data.filter(x => x.trim().toLowerCase() == this.input.value.toLowerCase())[0];
    
    if (r)
    {
      this.input.value = this.property ?  r[this.property] : r;
      this.showed = false;
        setTimeout(() => {
          this.showBorder = false;
          
        }, 200);
      this.dataChanged.emit(r);
      
      // this.input.los
      // console.log("HERE");

    }
  }

}
