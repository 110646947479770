export class User
{
    public id: number = 0;
    public name: string = "";
    public code: string = "";
    public level: number = 0;
    public active: boolean = true;

    constructor(x? : User){
        if(x)
        {
            this.id = x.id;
            this.name = x.name;
            this.code = x.code;
            this.level = x.level;
            this.active = x.active;
        }
    }


}

export class Customer
{

    public id: number= -1;
    public name: string ="";
    public phone1: string ="";
    public phone2: string="";
    public email: string="";
    public street: string="";
    public city: string="";
    public state: string="";
    public zip: string="";
    public log: string="";
    public country:string="";

    public devices: Device[] = [];


    constructor(x? : Customer){
        if(x != null)
        {
            this.id = x.id;
            this.name = x.name;
            this.phone1 = x.phone1;
            this.phone2 = x.phone2;
            this.email = x.email;
            this.street = x.street;
            this.city = x.city;
            this.state = x.state;
            this.zip = x.zip;
            this.log = x.log;
            this.country = x.country;
            this.devices = JSON.parse(JSON.stringify(x.devices));
        }
    }

    // public static async AllCustomers(callback: (error: Error, data: IList<Customer>) => void)
    // {
    //     var customers: IList<Customer> = new List<Customer>();
    //     var err = new Error();



    //     callback(err, customers);
    // }

}

export class Brand
{
    public id: number=-1;
    public name: string="";

    constructor(x? : Brand){
        if(x != null)
        {
            this.id=x.id;
            this.name=x.name;
        }
    }
}

export class Model
{
    public id: number=-1;
    public name:string="";
    public brand: Brand= new Brand();

    constructor(x? : Model){
        if(x != null)
        {
            this.id=x.id;
            this.name= x.name;
            this.brand= new Brand(x.brand);
        }

    }
}

export class Device
{
    public id: number=-1;
    public model: Model= new Model();
    public type: string="";
    public color:string="";
    public serial:string="";
    public password: string="";
    public logs: Log[] = [];

    constructor(x? : Device){
         if(x != null)
          {
              this.id= x.id;
              this.model= new Model(x.model);
              this.type= x.type;
              this.color= x.color;
              this.serial= x.serial;
              this.password= x.password;
              this.logs= JSON.parse(JSON.stringify(x.logs));
          }

    }
}

export class Order
{
    public id: number=-1;
    public customer: Customer=new Customer();
    public user: User=new User();
    public completed: boolean=false;
    public time: Date=new Date();
    public timecompleted: Date=new Date();
    public order_devices:  OrderDevice[]=[];
    //new List<OrderDevice>();

    constructor(x? : Order){
        if( x != null)
        {
            this.id= x.id;
            this.customer= new Customer(x.customer);
            this.user= x.user;
            this.completed= x.completed;
            this.time= x.time;
            this.timecompleted= x.timecompleted;
            this.order_devices = JSON.parse(JSON.stringify(x.order_devices));//.toList();
        }

    }

}

export class Service
{
    public id: number=-1;
    public order_device:OrderDevice=new OrderDevice();
    public name: string="";
    public price:number=0;

    constructor(x? : Service){
        if(x != null)
        {
            this.id= x.id;
            this.order_device= new OrderDevice(x.order_device);
            this.name= x.name;
            this.price= +x.price;

        }

    }
}

export class Receipt
{
    public id: number=-1;
    public customer:Customer=new Customer();
    public user:User=new User();
    public payment_method: string = '';
    public reference: string="";
    public time: Date= new Date();

    public grand_total: number=0;
    public order_devices: OrderDevice[] = [];
    receipt_time = new Date();



    constructor(x? : Receipt){
        if(x != null)
        {
            this.id= x.id;
            this.customer= new Customer(x.customer);
            this.user= new User(x.user);
            this.payment_method= x.payment_method;
            this.reference= x.reference;
            this.grand_total= x.grand_total;
            this.time= new Date(x.time);
            this.order_devices = (x.order_devices || []).clone();
            this.receipt_time = new Date(x.receipt_time);
        }

    }
}

export class TaxCharge
{
    public id: number=-1;
    public name: string="";

    public tax: number=0;


    constructor(x? : TaxCharge){
        if(x !=null)
        {
            this.id= x.id;
            this.name= x.name;
            this.tax= x.tax;
        }

    }
}

export class Charge
{

    public id: string="";
    public receipt: Receipt=new Receipt();
    public order_device:OrderDevice=new OrderDevice();
    public tax_charge: TaxCharge=new TaxCharge();
    public quantity: number=1;
    public name: string="";

    public total: number=0;
    public price: number=0;



    constructor(x? : Charge){
        if(x  != null)
        {
            this.id= x.id;
            this.receipt= new Receipt(x.receipt);
            this.order_device= new OrderDevice(x.order_device);
            this.tax_charge= new TaxCharge(x.tax_charge);
            this.quantity= x.quantity;
            this.price= x.price;
            this.total= x.total;
            this.name= x.name;
        }

    }

}

export class Note
{
    public name: string = '';
    public message: string = '';
    public time: Date = new Date();

    constructor (x?: Note)
    {
        if (x)
        {
            this.name = x.name;
            this.message = x.message;
            this.time = new Date(x.time);
        }
    }
}

export class Log
{
    public message: string = '';
    public time: Date = new Date();

    constructor (x?: Log)
    {
        if (x)
        {
            this.message = x.message;
            this.time = new Date(x.time);
        }
    }
}

export class OrderDevice
{
    [x: string]: any;
    public id: number=-1;
    public device:Device= new Device();
    public accessorie: string="";
    public note: Note[] = [];
    public log: Log[] = [];
    public time_due: Date= new Date();
    public completed: boolean= false;
    public completeddon:Date=new Date();
    public status: string="";
    public user: User=new User();
    public lsat_change: Date= new Date();
    public order: Order = new Order();
    public charges: Charge[] = [];

    public services:Service [] = [];

    public total: number=0;


    constructor(x? : OrderDevice){
        if(x != null)
        {
            this.id= x.id;

            this.device= new Device(x.device);
            this.order= new Order(x.order);
            this.accessorie= x.accessorie;
            this.note= JSON.parse(JSON.stringify(x.note));
            this.log=  JSON.parse(JSON.stringify(x.log));
            this.time_due= new Date(x.time_due);
            this.completed= x.completed;
            this.completeddon= new Date(x.completeddon);
            this.status= x.status;
            this.user= new User(x.user);
            this.lsat_change= new  Date(x.lsat_change);
            this.total = x.total;
            this.services= JSON.parse(JSON.stringify(x.services));
            this.charges = JSON.parse(JSON.stringify(x.charges || []));
        }

    }


}

export class Shift{
    public id:number= -1;
    public time:Date = new Date();
    public in:boolean = true;
    public user: User = new User();
    public made_by: User = new User();
    gps: {
        lat: number,
        lng: number
    } = {
        lat: 0,
        lng: 0
    }
    constructor(x?:Shift){
        if(x)
        {
            this.time = new Date(x.time);
            this.in = x.in;
            this.id = x.id;
            this.user = new User(x.user);
            this.made_by = new User(x.made_by);
            this.gps = {...x.gps};
        }
       }

 }
