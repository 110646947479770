<div class="combobox-container">

    <input [(ngModel)]='ngModel1' type="text" [placeholder]="placeholder" (focus)="showList()" (input)="search($event)" autocomplete="off" [disabled]='_disabled' [readOnly]='!canType' style="max-width: 100%;" [ngClass]="{'border-bottom': border}">
    <ng-container *ngIf='!hideIcons'>
        <i class="fas fa-caret-down icon" *ngIf="!showed" (click)="s()" [ngStyle]="{'color': _disabled ? 'gray' : 'black'}"></i>
        <i class="fas fa-times-circle icon close" *ngIf="showed" (click)="clear()"></i>

    </ng-container>

    <cdk-virtual-scroll-viewport id="combobox" class="combobox-list" [ngStyle]="{'max-height': showed == false ? '0' : getSize() + 'px', 'border-bottom': (showBorder == false? '0px solid transparent': '1px solid #bbb'), 'height' : getSize() + 'px'}" itemSize='21'
        style="width: 100%; z-index: 99999;">
        <button class="btn btn-clear" *cdkVirtualFor="let d of data" (click)="something(d)">{{property == ''? d : d[property]}}</button>
        <!-- <p *ngIf='data.length == 0 && original.length > 0'>No Results</p> -->
    </cdk-virtual-scroll-viewport>

</div>