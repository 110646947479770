import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from '@angular/router';
import {BaseService} from '../../../services/base.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  current: string;
  order_device_id:any = null;
  amazonOpen = false;
  jobAgreementOpen = false;

  amazonProducts = [];

  loading = false;
  jobAgreement = {
    id: null,
    name: 'job_agreement',
    value: ''
  };

  constructor(private location: Location, private router: Router, public baseService: BaseService, private aRouter: ActivatedRoute) {
    // console.log(location.path());
    this.order_device_id = aRouter.snapshot.paramMap.keys;
    if (this.checkRoute("/order-detail/"))
    {
      this.order_device_id = this.location.path().toLocaleLowerCase().replace('/order-detail/', '');
    }
    else
      this.order_device_id = null;

    router.events.subscribe(x => {
      // console.log(this.location.path());
      if (this.checkRoute("/order-detail/"))
      {
        this.order_device_id = this.location.path().toLocaleLowerCase().replace('/order-detail/', '');
      }
      else
        this.order_device_id = null;
    });
    

    
   
   }

   checkRoute(path): boolean {
   return this.location.path().toLocaleLowerCase().startsWith(path.toLowerCase());
  }

  ngOnInit() {
    console.log("HERE");
  }
  changeActive(evt, url = '') {
    if (evt.target.classList.contains("active"))
      return;
    
    let elements = document.getElementsByTagName("img");
  
    for (let i = 0; i < elements.length; i++)
    {
      elements[i].classList.remove("active");
    }
    evt.target.classList.add("active");

    if (this.location.path().toLowerCase() != url)
    {
      this.router.navigateByUrl("/" + url);
    } 
    
  }

  logout(){
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  async userOptionsChanged(evt) {
    let value = evt.target.value;

    switch (value)
    {
      case "2":
        const { value: formValues } = await Swal.fire({
          title: 'Switch User',
          html:
            '<form><input id="swal-input1" style="text-transform: uppercase; font-weight: bold;" class="swal2-input" placeholder="Username">' +
            '<input id="swal-input2" style="text-transform: uppercase; font-weight: bold;" class="swal2-input" placeholder="PASSWORD" type="password"></form>',
          focusConfirm: false,
          allowEnterKey: true,
          preConfirm: () => {
            return [
              (document.getElementById('swal-input1') as HTMLInputElement).value,
              (document.getElementById('swal-input2') as HTMLInputElement).value
            ]
          }
        });
        if (formValues) {

          // Swal.fire(JSON.stringify(formValues))
          let name = formValues[0] || '';
          let pass = formValues[1] || '';
          if (name.trim().length == 0 && pass.trim().length == 0)
          {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Information missing'
            })
          }
          else
          {
            name = name.toUpperCase();
            pass = pass.toUpperCase();
            this.baseService.post('user/login', {name: name, code: pass}).subscribe((d: any) => {
              localStorage.removeItem('token');
              localStorage.setItem('token', d.token);
              this.baseService.USER = d.user;
              Swal.fire({
                icon: 'success',
                title: 'User relogged',
                text: 'Welcome back: ' + d.user.name
              });
            }, err => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'User or password is incorrect'
              });
            });
          }
          // console.log(formValues);
        }
          break;
          case "3":
            const { value: formValues2 } = await Swal.fire({
              title: 'Add Technician',
              html:
                '<form><input id="swal-input1" style="text-transform: uppercase; font-weight: bold;" class="swal2-input" placeholder="Name">' +
                '<input id="swal-input2" style="text-transform: uppercase; font-weight: bold;" class="swal2-input" placeholder="PASSWORD" type="Password"></form>',
              focusConfirm: false,
              allowEnterKey: true,
              preConfirm: () => {
                return [
                  (document.getElementById('swal-input1') as HTMLInputElement).value,
                  (document.getElementById('swal-input2') as HTMLInputElement).value
                ]
              }
            });
            if (formValues2) {
    
              // Swal.fire(JSON.stringify(formValues))
              let name = formValues2[0] || '';
              let pass = formValues2[1] || '';
              if (name.trim().length == 0 && pass.trim().length == 0)
              {
                Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Information missing'
                })
              }
              else
              {
                name = name.toUpperCase();
                pass = pass.toUpperCase();
                this.baseService.post('user/technicians', {name: name, code: pass}).subscribe((d: any) => {
                  localStorage.removeItem('token');
                  localStorage.setItem('token', d.token);
                  this.baseService.USER = d.user;
                  Swal.fire({
                    icon: 'success',
                    title: 'Technician Added',
                    text: 'Welcome back: ' + d.user.name
                  });
                }, err => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'We couldnt add the user'
                  });
                });
              }
              // console.log(formValues);
            }
              break;
      case "4":
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
          break;
      case "5":
            this.amazonOpen = true;
            document.body.style.overflow = 'hidden';
            this.loadProducts();
            break;
      case "6":
            this.jobAgreementOpen = true;
            document.body.style.overflow = 'hidden';
            this.loadJobAgreement();
            break;
    }
    let select = document.getElementById('user-select') as HTMLSelectElement;
    select.value = "1";
  }

  closeAmazon() {
    this.amazonOpen = false;
    document.body.style.overflow = 'auto';
  }

  getCurrentPage() {
    let path = this.location.path().toLocaleLowerCase();

    if (path.startsWith('/open-ticket') || path == '')
      return 'Open Tickets <i class="fas fa-envelope-open"></i>';
    if (path.startsWith('/customers'))
      return 'Customers <i class="fas fa-users"></i>'
    if (path.startsWith('/new-order'))
      return 'New Order <i class="fas fa-plus"></i>'
    if (path.startsWith('/archived-tickets'))
      return 'Archived Tickets <i class="fas fa-archive"></i>'
    if (path.startsWith('/order-detail'))
      return 'Order Detail <i class="fas fa-info-circle"></i>'
    if (path.startsWith('/update-terms'))
      return 'Edit Terms <i class="far fa-file-alt"></i>'

    if (path.startsWith('/update-receipt'))
      return 'Edit Receipt <i class="fas fa-receipt"></i>'

    if (path.startsWith('/clock'))
      return 'Clock <i class="fas fa-clock"></i>'

    if (path.startsWith('/employee'))
      return 'Clock <i class="fas fa-database"></i>'


      

    if (path.startsWith('/prices'))
    return 'Prices <span style="font-weight: bold;">$</span>'

    return '';
  }


  getRoute = () => {
    return 'clock/' + this.baseService.USER.id;
  }

  loadJobAgreement() {

    this.loading = true;

    this.baseService.get("terms-conditions", true).subscribe((data:any)=>{
      // console.log(data);
      this.jobAgreement = data.find(x => x.name == 'job_agreement');
      this.loading = false;
  }, err => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'We could not load the job agreement'
    });
    this.loading = false;
    this.jobAgreementOpen = false;
  });
  }

  loadProducts() {
    
    this.loading = true;
    this.baseService.get('amazon', true).subscribe((d: any) => {
      this.amazonProducts = d;
      this.loading = false;
    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'We could not load the products'
      });
      this.loading = false;
    });
  }

  addProduct() {
    let product = prompt("Enter the product link");
    if (product)
    {
      // verify if the product link is valid with a regex
      // does not matter the domain
      const regex = /https?:\/\/(?:www\.)?([a-zA-Z0-9-]+)\.[a-zA-Z0-9-]+(?:\/[a-zA-Z0-9-]+)?/g;

      if (!regex.test(product))
      {
        return Swal.fire({
          icon: 'error',
          title: 'Invalid Link',
          text: 'The link is invalid'
        
        })
      }

      if (this.amazonProducts.some(p => p.url == product))
      {
        return Swal.fire({
          icon: 'warning',
          title: 'Product Already Added',
          text: 'The product is already in the list'
        });
      }



      this.loading = true;
      this.baseService.post('amazon', {url: product}, true).subscribe((d: any) => {
        
        this.loading = false;

        Swal.fire({
          icon: 'success',
          title: 'Product Added',
          text: 'The product has been added to the list',
          
        });

        this.amazonProducts.push(d);

        // this.amazonOpen = false;
      }, err => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err && err.message ? err.message : 'We could not add the product'
        });
      });

    }
  }

  deleteProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this product?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        
        this.loading = true;
        this.baseService.delete('amazon/' + product.id, true).subscribe((d: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Product Deleted',
            text: 'The product has been deleted'
          });

          this.amazonProducts = this.amazonProducts.filter(p => p.id != product.id);
          this.loading = false;
        }, err => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'We could not delete the product'
          });
        });
      }
    });
  }

  rescrapeProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to rescrape this product?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        
        this.loading = true;
        this.baseService.newPut('amazon/rescrape', {id: product.id}, true).subscribe((d: any) => {
          Swal.fire({
            icon: 'success',
            title: 'Product Rescraped',
            text: 'The product has been rescraped'
          });

          this.amazonProducts = this.amazonProducts.filter(p => p.id != product.id);
          this.loading = false;
        }, err => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'We could not delete the product'
          });
        });
      }
    });
  }

  closeJobAgreement() {
    this.jobAgreementOpen = false;
    document.body.style.overflow = 'auto';
  }

  async saveJobAgreement(){
    if (!this.jobAgreement.value)
    {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'The job agreement is empty'
      });
    }
    try {
      await this.baseService.put<any>("terms-conditions",{terms: JSON.stringify([
        this.jobAgreement
      ])} ,true);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Terms and conditions have been edited',
        showConfirmButton: true,
        timer: 1500
      });
      this.closeJobAgreement();
    } catch (ex) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong',
        showConfirmButton: true,
        timer: 1500
      });
    }
    // console.log(this.terms_front);
  }
} 
