<a class="btnback" routerLink="/open-tickets">
    <i class="fas fa-chevron-left"></i> Open Tickets
</a>

<div>
    <div>
     
    </div>
    <div class="od-container" [ngClass]="{'printing': isprintig}" *ngFor="let od of order_devices; let i= index" id="od-{{i}}">
        <div class="row">
            <div class="column-top">
                <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
            </div>
            <div class="column-top">
                <div class="row mb-40">
                    <div class="column w-6">
                        <h4 class="mb-23">Created on {{od.order.time | date:('medium')}}</h4>
                        <h2 class="mb-26">#{{od.id}}</h2>
                        <p>Taken by: <strong>{{od.order.user.name}}</strong></p>
                    </div>
                    <div class="column w-6 text-right">
                        <h3 class="mb-20">LAPC Networking Inc.</h3>
                        <p class="mb-26">3609 W, Magnolia Blvd.<br>Burbank, CA 91505</p>
                        <p>(818) 478-2222 | www.lapcnetworking.com</p>
                    </div>
                </div>
                <div class="row mb-20">
                    <div class="column w-6">
                        <p class="title mb-12">CUSTOMER</p>
                        <!-- <h3 class="mb-12">{{od.order.customer.name}}</h3> -->
                        <!-- <input class="input-charge" [(ngModel)]='od.order.customer.name' (input)='capitilaze($event, od.order.customer.name)' (blur)='updateName($event, od.order.customer.name)' type="text" style="font-weight: bold;"> -->
                        <input class="input-charge" [(ngModel)]='od.order.customer.name' (input)='capitilaze($event, od.order.customer)' (blur)='updateName($event, od.order.customer.name); updateCustomer(od.order.customer)' type="text" style="font-weight: bold;">
                        <p>{{od.order.customer.street == ''? '' : od.order.customer.street}}<br>{{od.order.customer.city == ''? '' : od.order.customer.city+','}} {{od.order.customer.state == ''? '' : od.order.customer.state+'.'}} {{od.order.customer.zip}}
                            <br>{{od.order.customer.phone1}} | {{od.order.customer.email}}</p>

                    </div>
                    <div class="column w-6">
                        <p class="title mb-12">NOTES</p>
                        <div class="notes-container">
                            <div *ngFor="let note of od.note">
                                <p class="scroll-items">{{note.message}}</p>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="row mb-40">

                    <div class="column w-6">
                        <p class="title mb-12">DEVICE</p>
                        <h3 class="mb-12">{{od.device.type}} {{od.device.model.brand.name}} {{od.device.model.name}}</h3>
                        <p>Color: {{od.device.color}}</p>
                        <p>Password: {{od.device.password}}</p>
                        <p>Serial: {{od.device.serial}}</p>
                        <p>Accesories: {{od.accessorie}}</p>

                    </div>
                </div>
                <div class="row table mb-17">
                    <div class="column w-10">
                        <h3 class="terms w-12">SERVICE DESCRIPTION</h3>
                    </div>
                    <div class="column w-2">
                        <h3 class="terms w-12">TOTAL</h3>
                    </div>
                </div>
                <hr class="mb-12">
                <div style="min-height: 200.8px; max-height: 200.8px; overflow: hidden;">

                    <div class="row table mb-17 service-list">
                        <ng-container *ngFor='let service of od.services'>
                            <div class="column w-10 mb-12">
                                {{service.name}}
                            </div>
                            <div class="column w-2 mb-12">
                                {{service.price | currency}}
                            </div>
                        </ng-container>
                        <!-- <div class="column w-12" *ngFor="let services of od.services">
                            <p class="w-12">{{services.name}}</p>
                        </div>
                        <div class="column w-6" *ngFor="let services of od.services">
                            <p class="w-12">${{services.price}}</p>
                        </div> -->
                    </div>
                </div>
                <div class="row table mb-40">
                    <div class="column w-10">
                        <p class="signature tright">Estimated Grand Total</p>
                    </div>
                    <div class="column w-2">
                        <h3 class="gtotal">{{getTotal(od) | currency}}</h3>
                    </div>

                </div>
                <div class="mb-12 scroll-2">
                    <h3 class="mb-12 terms" style="font-size: 7.81px !important;">TERMS AND CONDITIONS</h3>
                    <textarea [(ngModel)]="terms_front.value" style="overflow: hidden; font-family: 'Open Sans', sans-serif; font-weight: 300 !important;" class="editable" name="" id="" cols="150" rows="13" readonly></textarea>
                </div>
                <!-- <h3 class="terms mb-40" style="font-size: 7.90px !important;"></h3> -->
                <div class="mb-40" style="font-size: 7.8px; font-weight: bold;">BY SIGNING BELOW, I AGREE TO ALL THE TERMS AND CONDITIONS DESCRIBED ABOVE AND ON THE BACK OF THIS FORM.</div>
                <div class="row table mb-40">
                    <div class="column w-6">
                        <hr class="w-12 mb-6">
                        <p class="signature w-12" style="font-size: 10px !important;">NAME</p>
                    </div>
                    <div class="column w-6">
                        <hr class="w-12 mb-6">
                        <p class="signature w-12" style="font-size: 10px !important;">DATE</p>
                    </div>
                </div>
                <div class="column w-6">
                    <hr class="w-12 mb-6">
                    <p class="signature w-12" style="font-size: 10px !important;">SIGNATURE</p>
                </div>

            </div>

        </div>

    </div>
    <div class="od-container" [ngClass]="{'printing': isprintig}" id="terms">
        <div class="row">
            <div class="column-top">
                <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
            </div>
            <div class="column-top">
                <h3 class="terms mb-12">TERMS AND CONDITIONS (CONTINUED)</h3>
                <textarea [(ngModel)]="terms_back.value" class="editable" style="overflow: hidden; font-family: 'Open Sans', sans-serif; font-weight: 300 !important;" name="" id="" cols="150" rows="80" readonly></textarea>
            </div>

        </div>
    </div>

</div>

<div class="btn-container floating-buttons">
    <button class='btn btn-black btn-big' [disabled]='order_devices.length == 0' (click)='sendMail()'>SEND TO EMAIL</button>
    <button class='btn btn-black btn-big' [disabled]='order_devices.length == 0' (click)='print()'>PRINT</button>
</div>