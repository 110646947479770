<div class="customer-box container">
    <p style="margin: 10px 0;">Devices</p>
    <div class="customer-box-header" style="align-items: center;">
        <div style="min-height: 32px; max-height: 32px; overflow: scroll; display: flex; flex-wrap: nowrap; justify-content: flex-start; margin-bottom: 20px;">
            <button (click)="setDevice(device)" *ngFor='let device of order.customer.devices' class="btn btn-outline btn-black no-border btn-devices">{{device.type}} {{device.model.brand.name}} {{device.model.name}}</button>
        </div>
    </div>
    <!-- <div style="min-height: 122px; max-height: 32px; overflow: scroll; display: flex; flex-wrap: nowrap; justify-content: flex-start; margin-top: 10px;">
        <div>
            <div *ngFor='let od of order.order_devices; let i = index;' class="btn btn-boxes" [ngClass]="{'active': i == current}" style="min-width: max-content; position: relative;">
                <div style="width: 100%; min-height: 22px; max-height: 22px;">

                    <button (click)='removeOrderDevice(i)' class="btn btn-clear no-padding" style="color: red; font-size: 14px; display: block; margin: 0 0 0 auto; position: relative; z-index: 2;" *ngIf="order.order_devices.length > 1"><i class="fas fa-times-circle"></i></button>
                </div>
                <p style="display: flex; width: 100%; justify-content: space-between;">

                    Device {{i + 1}} <a>{{getTotalOrderDevice(i) | currency}}</a></p>
                    {{od.device.type}} {{od.device.model.brand.name}} {{od.device.model.name}}
                <button (click)='current = i;' class="btn btn-clear no-padding" style="position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%;"></button>
            </div>
            <button class="btn btn-boxes" (click)='addNewOrderDevice($event)'><i class="fas fa-plus" style="display: block; margin: 0 auto; padding: 0 80px;"></i></button>
        </div>
    </div> -->


    <div class="od-container">
        <div class="od" *ngFor='let od of order.order_devices; let i = index;' [ngClass]="{'active': i == current}">
            <div>
                <p class="pos-price">
                    Device {{i + 1}}
                    <span class="bold">{{totalByOD(i) | currency}}</span>
                </p>
                <p class="bold">
                    {{od.device.type}} {{od.device.model.brand.name}} {{od.device.model.name}}
                </p>
            </div>
            <button class="btn btn-clear no-padding" (click)='current = i'></button>
        </div>
        <div class="od">
            <button class="btn btn-clear no-padding" (click)='addNewOrderDevice($event)'>+</button>
        </div>
    </div>

    <div class="customer-box-content cont">
        <div class="fields">
            <div class="field w-12">
                <label for="">Brand</label>
                <app-combobox [value]='order.order_devices[current].device.model.brand.name' [property]='"name"' [_data]='brands' [placeholder]='"Select a brand"' (dataChanged)='brandChanged($event)'></app-combobox>
            </div>
            <div class="field w-12">

                <label for="">Model</label>
                <app-combobox [value]='order.order_devices[current].device.model.name' [placeholder]='"Select a model"' [_data]='models' [property]='"name"' [disabled]='order.order_devices[current].device.model.brand.name.trim() == ""' (dataChanged)='modelChanged($event)'></app-combobox>
            </div>

            <div class="field w-12">

                <label for="">Device Type</label >
                    <app-combobox [value]='order.order_devices[current].device.type' [placeholder]='"Select a type"' [_data]='types'  [disabled]='order.order_devices[current].device.model.name.trim() == ""' (dataChanged)='order.order_devices[current].device.type = $event.toUpperCase().trim()'></app-combobox>
            </div>

            <div class="field w-12">
                <label for="">Serial Number</label>
                <input type="text" [(ngModel)]='order.order_devices[current].device.serial' placeholder="XXXXXXXXXX">
            </div>



            <div class="field w-12">
                <label for="">Color</label>
                <input type="text" [(ngModel)]='order.order_devices[current].device.color' placeholder="Color Name">
            </div>

            <div class="field w-12">
                <label for="">Password</label>
                <input type="text" style="text-transform: none !important;" [(ngModel)]='order.order_devices[current].device.password' placeholder="Password">
            </div>

            <div class="field w-12">
                <label for="">Due Date</label>
                <!-- <ngx-date-time-picker [selectedDateTime]="date" (getData)="getStartDate($event)" [options]="{timePicker: false}" [dateTimeFormat]="'MM/DD/YYYY'"></ngx-date-time-picker> -->
                <!-- <input type="text" ngxDaterangepickerMd [(ngModel)]="order.order_devices[current].time_due" class="form-control dates" opens="center" drops="down" readonly /> -->
                <div style="display: flex; align-items: center;">
                    <input [owlDateTime]="dt2" placeholder="Date Time" [(ngModel)]='order.order_devices[current].time_due' readonly>
                    <span [owlDateTimeTrigger]="dt2"><i class="fa fa-calendar"></i></span>
                    <owl-date-time #dt2></owl-date-time> 
                </div>


            </div>
        </div>

        <div class="customer-box container-1 space">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <p>Estimated Price</p>
                <button (click)='addService($event)' [disabled]='!isValidToAddService()' class="btn btn-clear no-padding bold" style="font-size: 16px;"><i class="fas fa-plus red"></i> Add Service</button>
            </div>
            <div class="services-container" id="services">
                <div class="service" *ngFor='let service of order.order_devices[current].services; let i = index;'>
                    <p>{{i + 1}}.)</p>
                    <input type="text" (input)='capitilaze($event, service)' [(ngModel)]='service.name' placeholder="Service Name" (keyup.enter)='enterServicePressed($event, service)'>
                    <input type="text" [(ngModel)]='service.price' placeholder="Price" mask="separator.2" thousandSeparator=",">
                    <!-- <p class="font-12">$100.00 </p> -->
                    <button (click)='removeService($event, i)' class="btn btn-clear no-padding"><i class="fas fa-times"></i></button>

                </div>
            </div>

            <hr/>

            <!-- <hr/> -->
            <p style="text-align: end;" class="font-13">Total {{totalByOD(current) | currency}}</p>
        </div>
    </div>
    <div class="fields">
        <div class="field w-6 sm-12" *ngIf='order.order_devices[current].note[0]'>
            <label for="">Notes</label>
            <textarea [(ngModel)]='order.order_devices[current].note[0].message' style="resize: none;"></textarea>
        </div>

        <div class="field w-6 sm-12">
            <label for="">Accessories</label>
            <textarea [(ngModel)]='order.order_devices[current].accessorie' style="resize: none;"></textarea>
        </div>
    </div>

    <div class="container btn-container">
        <button class="btn btn-large" (click)="submit()" [disabled]="isSending">SUBMIT</button>
    </div>