<div class="login-container">

    <img src="../../../assets/imgs/background.jpg" alt="">
    <h1>LAPC Networking INC. V1.7.7</h1>
    <form #form='ngForm' (ngSubmit)="login(form)">
        <h2>Login</h2>
        <div class="field-v2">
            <label for="name">USERNAME</label>
            <input id="name" name="name" type="text" placeholder="TYPE USERNAME" [(ngModel)]='user.name'>
        </div>

        <div class="field-v2">
            <label for="code">PASSWORD</label>
            <input id="code" type="password" name="code" placeholder="TYPE PASSWORD" [(ngModel)]='user.code'>
        </div>
        <button class="btn btn-black btn-block btn-0br" [disabled]="!isValid()" type="submit" [ngStyle]="{'opacity' : loginText == 'LOGIN'? 1: .7}">{{loginText}}</button>
    </form>
</div>
