import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Shift, User } from '@classes/classes';
import * as moment from 'moment';
import { title } from 'process';
import { BaseService } from 'src/app/services/base.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Route, Router, RouterLink } from "@angular/router";


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  hrHand: HTMLDivElement;
  minHand: HTMLDivElement;
  secHand: HTMLDivElement;
  hoursDet = false;
  shiftGrouped: Shift[][] = [];
  isClock = true;
  shift: Shift[] = [];
  titleInfo: string = '';
  toDate;
  user_id;
  user = new User();
  shiftSelected: Shift = null;
  selected;
  loading = false;
  constructor(private baseService: BaseService, private route: ActivatedRoute, private router: Router) {
    this.user_id = route.snapshot.params.user_id;
    let today = moment().format('YYYY-MM-DD');
    const reqUrl = `shift/${today}/${today}/${this.user_id}`;

    baseService.get('employee/' + this.user_id, true).subscribe((data: any) => {
      this.user = new User(data);
    }, err => {
      alert('Error: ' + err);
      this.router.navigateByUrl('/');
    });
    baseService.get(reqUrl, true).subscribe((data: any) => {
      this.shift = data;
      this.shiftGrouped = this.shift.groupBy(x => moment(x.time).format("YYYY/MM/DD"));

    }, err => {
      console.log(err);
    })
  }

  ngOnInit() {
    let end = moment();
    let last: any = moment();
    this.selected = {
      startDate: last,
      endDate: end
    }
    this.secHand = document.querySelector('#sechand');
    this.minHand = document.querySelector('#minhand');
    this.hrHand = document.querySelector('#hrhand');
    const date = new Date();
    this.updateClock(date);
    setInterval(() => {
      const date = new Date();
      this.updateClock(date);
    }, 1000);
  }

  getLocation() {
    let gps;

    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        (navigator.geolocation.getCurrentPosition((p) => {
          const lat = p.coords.latitude
          const lng = p.coords.longitude;
          resolve({ lat, lng });
        }, err => reject(err), { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }));
      })

    }

    return null;

  }

  async punch() {

    this.loading = true;
    let gps;

    try {
      gps = await this.getLocation();
    }
    catch (ex) {
      this.loading = false;
      return alert('Error: ' + ex.message);
    }

    // console.log(gps);
    this.baseService.post('shift', { gps: JSON.stringify(gps), user_id: this.user_id }, true).subscribe(
      (data: Shift) => {
        this.loading = false;
        Swal.fire({
          icon: 'success',
          title: '',
          text: 'Punch successful'
        });
        this.shift.unshift(data);
        this.shiftGrouped = this.shift.groupBy(x => moment(x.time).format("YYYY/MM/DD"));
      },
      err => {
        console.log(err);
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: '',
          text: err.includes('duplicate key value violates') ? `You can't punch within one minute.` : err
        });
      }

    )
  }


  updateClock(date: Date) {


    // this.secHand.style.transition = date.getSeconds() == 0 ? 'none' : '.3s all ease';
    this.secHand.style.transform = `rotate(${date.getSeconds() * 6}deg)`;


    // this.secHand.style.transition = date.getMinutes() == 0 ? 'none' : '.3s all ease';
    this.minHand.style.transform = `rotate(${date.getMinutes() * 6}deg)`;

    // this.secHand.style.transition = date.getHours() == 0 ? 'none' : '.3s all ease';
    this.hrHand.style.transform = `rotate(${(date.getHours() * 30 + date.getMinutes() * 0.5)}deg)`;
  }

  openMap(data) {
    this.shiftSelected = data;
    this.titleInfo = moment(this.shiftSelected.time).format('LLLL');
    // this.titleInfo = `User: ${this.shiftSelected.user.name}\n logged ${this.shiftSelected.in? 'in' : 'out'} at ${moment(this.shiftSelected.time).format('LLLL')} made by: ${this.shiftSelected.made_by.name}`;

  }

  datesChanged(evt) {
    if (!evt.startDate || !evt.endDate) return;
    let startDate = moment(evt.startDate).format('YYYY-MM-DD');
    let endDate = moment(evt.endDate).format('YYYY-MM-DD');
    this.baseService.get("shift/" + startDate + '/' + endDate + '/' + this.user_id, true).subscribe((data: any) => {
      this.shift = data;
      this.shiftGrouped = this.shift.groupBy(x => moment(x.time).format("YYYY/MM/DD"));
    }, err => {
      console.log(err);
    })

  }

  getTotalHours(shifts: Shift[]) {
    let sec = 0;

    let temp = [...shifts].sort((x, y) => x.time > y.time ? 1 : -1);
    for (let i = 0; i < temp.length; i += 2) {
      let start = temp[i];
      let end = temp[i + 1] || temp[i];
      sec += moment(end.time).diff(moment(start.time), 'seconds');
    }
    return +Number(sec / 60 / 60).toFixed(2);
  }


  getGrandTotalHours() {
    return this.shiftGrouped.sumBy(x => this.getTotalHours(x));
  }

}
