
<div class="dummy-card">

    <div class="header-container" style="width: 100%; display: flex; justify-content: center; align-items: center; margin-bottom: 2rem; margin-top: 3rem; ">
        <h4 style="text-align: center; font-size: 16px; ">Clock Information for: {{user?.name}}</h4>
            <input *ngIf="isClock" type="text" (datesUpdated)='datesChanged($event)' ngxDaterangepickerMd [(ngModel)]="selected" class="form-control dates" opens="center" drops="down" readonly style="font-weight: bold; background: transparent !important;" />
    </div>


    <div class="clock">
        <div class="num num1"><div>1</div></div>
        <div class="num num2"><div>2</div></div>
        <div class="num num3"><div>3</div></div>
        <div class="num num4"><div>4</div></div>
        <div class="num num5"><div>5</div></div>
        <div class="num num6"><div>6</div></div>
        <div class="num num7"><div>7</div></div>
        <div class="num num8"><div>8</div></div>
        <div class="num num9"><div>9</div></div>
        <div class="num num10"><div>10</div></div>
        <div class="num num11"><div>11</div></div>
        <div class="num num12"><div>12</div></div>
        <div class="hr-hand" #hrhand id="hrhand"></div>
        <div class="min-hand" #minhand id="minhand"></div>
        <div class="sec-hand" #sechand id="sechand"></div>
    </div>

    <div class="space">
        <div class="position">
            <button class="btn" (click)="punch()">Punch </button>
            
        <button class="btn" (click)="hoursDet=!hoursDet" *ngIf="shiftGrouped.length > 0">SEE DETAILS</button>
    
        </div>
        <p style="font-size: 16px; font-weight: bold; padding: 10px 5px;">Recent Activity</p>


        <div class="hour">
            <p style="width: 25%; color: #868686;">MADE BY</p>
            <p style="width: 60%; color: #868686;">TIME</p>
            <p style="width: 15%; color: #868686;">TYPE</p>
        </div>
        <div class="scroll"> 
            
            <div class="hour" *ngFor="let s of shift" (click)="openMap(s)" style="cursor: pointer;">
<!--                <p>{{}}</p>-->
                <p style="width: 25%">{{s.made_by.name}}</p>
                <p style="width: 60%">{{s.time | date:('MM/dd/yyyy hh:mm:ss a')}}</p>
                <p style="width: 15%">{{s.in == true? 'IN' : 'OUT'}}</p>
            </div>

            <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); z-index: 999999;" *ngIf="shiftSelected" (click)="shiftSelected=null">
                <div style="position: absolute; width: 90%; max-width: 600px; background: white; padding: 2rem; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 1rem;" (click)="$event.stopPropagation()">
                    <div style="margin-bottom: 16px; display: flex; justify-content: space-between;">
                        <h4>Punch details for {{shiftSelected.user.name}}</h4>
                        <i style="cursor: pointer;" class="fas fa-times" (click)="shiftSelected=null"></i></div>
                    <app-map [data]="{ title:  shiftSelected.user.name, lat: shiftSelected.gps.lat, lng: shiftSelected.gps.lng }"></app-map>
                    <div style="font-size: 14px;">
                        <p style="margin-top: 16px;">User: {{shiftSelected.user.name}}</p>
                        <p>Logged {{shiftSelected.in? 'in' : 'out'}}</p>
                        <p>Made by: {{shiftSelected.made_by.name}}</p>
                        <p>At {{titleInfo}}</p>
                    </div>
                </div>
            </div>

            <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); z-index: 999999;" *ngIf="hoursDet" (click)="shiftSelected=null; hoursDet=!hoursDet">
                <div style="position: absolute; width: 90%; max-width: 600px; background: white; padding: 2rem; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 1rem;" (click)="$event.stopPropagation()">
                    
                    <div style="margin-bottom: 16px; display: flex; justify-content: space-between;">
                        <h4>Clock details for {{shiftGrouped[0][0]?.user.name}}</h4>
                        <i style="cursor: pointer;" class="fas fa-times" (click)="shiftSelected=null; hoursDet=!hoursDet"></i></div>
                    <div style="max-height: 400px; min-height: 400px; overflow: auto;">
                        <div style="margin-bottom: 1rem;" *ngFor="let s of shiftGrouped">
                            <div class="hour" style="font-size: 14px;">
                                <p style="width: 100%">{{s[0].time | date:'EEEE, MMMM d, y'}}</p>
                            </div>
                            <div class="hour">
                                <p style="width: 35%; color: #868686;">MADE BY</p>
                                <p style="width: 40%; color: #868686;">TIME</p>
                                <p style="width: 25%; color: #868686;">TYPE</p>
                            </div>
                            <div class="hour" style="font-size: 14px;" *ngFor='let shift of s'>
                                <p style="width: 35%">{{shift.made_by.name}}</p>
                                <p style="width: 40%">{{shift.time | date:('hh:mm:ss a')}}</p>
                                <p style="width: 25%">{{shift.in == true? 'IN' : 'OUT'}}</p>
                            </div>
                            <div class="hour">
                                <p style="width: 75%; color: #868686;">TOTAL HOURS</p>
                                <p style="width: 25%; color: #868686;">{{getTotalHours(s)}} HR</p>
                            </div>
                        </div>
                    
                    
                    </div>

                    <div class="hour" style="padding-top: 1rem;" *ngIf="shiftGrouped.length > 1">
                        <p style="width: 75%; color: #868686;">GRAND TOTAL HOURS</p>
                        <p style="width: 25%; color: #868686;">{{getGrandTotalHours()}} HR</p>
                    </div>
                </div>
            </div>


            <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); z-index: 999999;" *ngIf="loading">
                <div style="position: absolute; width: 90%; max-width: 600px; background: white; padding: 2rem; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 1rem;" (click)="$event.stopPropagation()">
                    <img src="https://i.stack.imgur.com/kOnzy.gif"  style="display: block; margin: 0 auto; width: 48px; height: 48px;">
                    <h3 style="text-align: center; margin-top: 1rem;">SAVING RECORD...</h3>
                </div>
            </div>

        </div>
    </div>
</div>
