import { Component, OnInit } from '@angular/core';
import {BaseService} from "../../services/base.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: 'app-update-receipt',
  templateUrl: './update-receipt.component.html',
  styleUrls: ['./update-receipt.component.scss']
})
export class UpdateReceiptComponent implements OnInit {
  paymentMethods = ['CASH', 'CHECK', 'CARD'];

  // receiptText = '';
  receipt;
  constructor(public baseService: BaseService, private route: Router) {
    if (this.baseService.USER.level < 9)
      this.route.navigateByUrl('/open-tickets');
    baseService.get("terms-conditions", true).subscribe((data:any)=>{
      console.log(data);
      this.receipt = data.find(x => x.name == 'receipt');
      // console.log(this.terms_front);
      // console.log(this.terms_back);
    })

  }

  ngOnInit() {
  }

  async updateTerms(){
    try {
      await this.baseService.put<any>("terms-conditions",{terms: JSON.stringify([this.receipt])} ,true);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Receipt text has been edited',
        showConfirmButton: true,
        timer: 1500
      });

      setTimeout(() => {
        this.route.navigateByUrl('/open-tickets');
      }, 1500);

    } catch (ex) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong',
        showConfirmButton: true,
        timer: 1500
      });
      // alert(JSON.stringify(ex));
      console.log(ex);
    }
    // console.log(this.terms_front);
  }

}
