<a class="btnback" routerLink="/open-tickets">
    <i class="fas fa-chevron-left"></i> Open Tickets
</a>
<div class="receipt-container" id="receipt" [ngClass]="{'printing': printing}">
    <div class="row">
        <div class="column-top">
            <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
        </div>
        <div class="column-top">
            <div class="row mb-114">
                <div class="column w-6">
                   
                    <div style="display: flex; align-items: center;" class="mb-23">
                        <h4 class="" style="width: auto !important; margin-right: 10px;">Created on </h4>
                        <div style="display: flex; align-items: center;">
                            <input style="width: 138px;" [owlDateTime]="dt2" placeholder="Date Time" [(ngModel)]='receipt.receipt_time' readonly>
                            <span *ngIf="receipt_id == -1" [owlDateTimeTrigger]="dt2"><i class="fa fa-calendar"></i></span>
                            <owl-date-time #dt2></owl-date-time>
                        </div>
                    </div>
                
                    <h2 class="mb-26">Receipt #{{receipt_id}}</h2>
                    <p>Issued by: <strong>{{issuedBy}}</strong> | Printed by: <strong>{{baseService.USER.name}}</strong></p>
                </div>
                <div class="column w-6 text-right">
                    <h3 class="mb-20">LAPC Networking Inc.</h3>
                    <p class="mb-26">3609 W, Magnolia Blvd.<br>Burbank, CA 91505</p>
                    <p>(818) 478-2222 | www.lapcnetworking.com</p>
                </div>
            </div>
            <div class="row mb-80">
                <div class="column w-6">
                    <p class="title mb-17">CUSTOMER</p>
                    <!-- <input class="mb-20">{{receipt.customer.name}} -->
                    <input class="input-charge" [disabled]='receipt.id != -1' [(ngModel)]='receipt.customer.name' (input)='capitilaze($event, receipt.customer)' (blur)='nameBlur($event, receipt.customer); updateCustomer()' type="text" style="font-weight: bold;">
                    <p class="mb-26" *ngIf='receipt.customer.street != ""'>{{receipt.customer.street}}<br>{{receipt.customer.city}}, {{receipt.customer.state}} {{receipt.customer.zip}}</p>
                    <p>{{receipt.customer.phone1}}<span *ngIf='receipt.customer.email != ""'> | {{receipt.customer.email}}</span></p>
                </div>
                <div class="column w-6">
                    <p class="title mb-12">PAYMENT METHOD</p>
                    <div class="payment-methods mb-12">
                        <button class="btn btn-outline" [disabled]='receipt.id != -1' style="opacity: 1 !important;" *ngFor='let pm of paymentMethods' [ngClass]="{'active': receipt.payment_method == pm}" (click)='receipt.payment_method = pm'>{{pm}}</button>
                    </div>
                    <textarea class="reference" [readOnly]='receipt.id != -1' [(ngModel)]='receipt.reference' placeholder="Notes (Ref #, Check #, etc)"></textarea>
                </div>
            </div>

            <div class="row table">
                <div class="column">
                    <p class="title">DEVICE #</p>
                </div>
                <div class="column">
                    <p class="title">DESCRIPTION</p>
                </div>
                <div class="column">
                    <p class="title">QTY</p>
                </div>

                <div class="column">
                    <p class="title">PRICE</p>
                </div>
                <div class="column">
                    <p class="title">TAX</p>
                </div>
                <div class="column ">
                    <p class="title">TOTAL </p>
                </div>
            </div>
            <hr>
            <ng-container *ngFor='let od of order_devices'>

                <div class="row align-top table mb-12">
                    <p class="id">#{{od.id}}</p>
                    <div class="column">
                        <h4 class="mb-6">{{od.device.model.name}}</h4>
                        <p>{{od.device.serial}}</p>
                    </div>
                </div>

                <div class="row table" *ngFor='let c of od.charges; let i = index'>
                    <p class="title"></p>
                    <div>
                        <input [readOnly]='receipt.id != -1' class="input-charge" [(ngModel)]='c.name' (input)='capitilaze($event, c)' (blur)='nameBlur($event, c)' type="text" placeholder="Type Charge Name">
                    </div>
                    <p class="title">1</p>
                    <div class="money">
                        <span>$</span> <input *ngIf='receipt.id != -1' class="input-charge" [readOnly]='true' [value]="c.price | number:'1.2'" type="text"> <input *ngIf='receipt.id == -1' class="input-charge" [readOnly]='receipt.id != -1' (input)='setTotalCharge(c)'
                            [(ngModel)]="c.price" (blur)="inputBlur($event)" type="text" placeholder="Type price" mask="separator.2" thousandSeparator=",">
                    </div>
                    <div>
                        <app-combobox [defaultText]="c.tax_charge?.tax + '%'"  *ngIf='receipt.id == -1' [_data]='taxCharges' [property]='"name"' [canType]='false' [border]='false' [hideIcons]='true' placeholder='Select' (dataChanged)='taxChanged($event, c)'></app-combobox>
                        <!-- <p  class="bold" style="font-weight: bold;">{{c.tax_charge.tax}}%</p> -->
                        <!-- <p *ngIf=( focus)='showMoney = false'></p>
                        <select (blur)='showMoney = true' (focus)='showMoney = false' (change)='taxChanged($event)' [(ngModel)]='c.tax_charge.id' (ngModelChange)='modelChanged($event)'>
                        <option value="-1" disabled selected>Select</option>
                        <option *ngFor='let tax of taxCharges' [value]="tax.id">{{showMoney ? tax.tax + '%' : tax.name}}</option> -->
                        <!-- <option value="0">LABOR</option> -->
                        <!-- </select> -->
                    </div>
                    <h4>{{c.total | currency}}</h4>
                    <button class="btn btn-clear no-padding close" *ngIf='od.charges.length > 1 && receipt.id == -1' (click)='removeCharge(od.charges, i)'><i class="fas fa-times"></i></button>
                    <!-- <div class="column">
                </div>
                <div class="column">
                </div>
                <div class="column">
                </div>

                <div class="column">
                </div>
                <div class="column">
                </div>
                <div class="column ">
                </div> -->
                </div>
                <button class="btn btn-clear add" (click)='addCharge(od.charges)' *ngIf='!printing && receipt.id == -1'>Add charge <i class="fas fa-plus"></i></button>
                <div class="total-container mt-6">
                    <p>Device total</p>
                    <h4>{{getTotalCharge(od.charges) | currency}}</h4>
                </div>
            </ng-container>
            
            
            <div class="total-container mt-20">
                <p>Grand total</p>
                <h2>{{getGrandTotal() | currency}}</h2>
            </div>

            <p class="mt-20" style="text-align: center; line-height: 18px; white-space: pre;">
                {{receipt_text}}
<span style="font-weight: bold; font-size: 24px; display: inline-block; margin-top: 10px;">LAPC Networking Inc.</span>
            </p>
        </div>

    </div>
</div>

<div class="btn-container floating-buttons" *ngIf='receipt_id != -1'>
    <button class='btn btn-black btn-big' [disabled]='!isValid() || printing' (click)='sendMail()'>SEND TO EMAIL</button>
    <button class='btn btn-black btn-big' [disabled]='!isValid() || printing' (click)='printPdf(1)'>PRINT</button>
</div>

<div class="btn-container floating-buttons" *ngIf='receipt_id == -1'>
    <button class='btn btn-black btn-big' [disabled]='!isValid() || printing' (click)='printPdf(null)'>CREATE RECEIPT</button>
</div>