import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BaseService } from '../../services/base.service';
import { User } from '../../classes/classes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  loginText: string = 'LOGIN';

  user: User= new User();

  constructor(private router: Router,  private baseService: BaseService) {}

  ngOnInit() {
  }

  login(form: NgForm) {

    let username: string  = form.value.name;
    let password: string = form.value.code;
    if (!username || username.trim() == '' || !password || password.trim() == '') {
      this.loginText = 'INFORMATION MISSING';
      setTimeout(() => {
        this.loginText = 'LOGIN';
      }, 1500);
      return;
    }

    let user = {
      name: username.toUpperCase(),
      code: password
    };


    this.baseService.post("user/login", user).subscribe((d: any) => {
      localStorage.setItem("token", d.token);
      
      this.baseService.USER = d.user;
      Swal.fire(
        `Welcome back ${d.user.name}!`

      ).then(result => {
        this.router.navigateByUrl("/open-tickets");
      });

    }, (err) => {
      console.clear();
      console.log(err);
      localStorage.removeItem("token");
      if(err.status != 500){
        this.loginText = 'WRONG USER/PASSWORD';
        setTimeout(() => {
          this.loginText = 'LOGIN';
        }, 1500);
        this.user = new User();
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Error',
        //   text: 'User or password is incorrect'
        // }).then(r=> this.user = new User());
        // form.reset();

      }
    });
  }

  isValid(): boolean {

  
    return this.user.name.trim().length > 0 && this.user.code.trim().length > 0; 
  }
}


