import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Router } from '@angular/router';
import { Order, Device, Model, Brand, OrderDevice, Service, User } from 'src/app/classes/classes';
import Swal from 'sweetalert2';
import { Note } from '../../../classes/classes';

@Component({
  selector: 'app-input-device',
  templateUrl: './input-device.component.html',
  styleUrls: ['./input-device.component.scss']
})
export class InputDeviceComponent implements OnInit {
  original: any;
  isArchived: any;

  @Input() public set customer(c)
  {
    this.order.customer = c; 
  }
  current: number = 0;
  order: Order = new Order;
  isSending:boolean = false;
  selectedCustomer:any = {
    id: -1,
    name: '',
    phone1: '',
    phone2: '',
    email: '',
    street: '',
    city: '',
    zip: ''
  };

  customers:any = [];
  constructor(public baseService: BaseService, private router: Router) {

    this.baseService.get('brand', true).subscribe((data: Brand[]) => this.brands = data, err => console.log(err));

    baseService.get("device/type", true).subscribe((data: any[]) => this.types = data.map(x => x.type),
    err => console.log(err));

    console.log(this.types);
    let od = new OrderDevice();
    let note = new Note();
    note.name = baseService.USER.name;
    od.note.push(new Note());
    this.order.order_devices.push(od);

    this.baseService.get('model', true).subscribe((data: Model[]) => this.allModels = data, err => console.log(err));

    this.baseService.get('brand', true).subscribe((data: Brand[]) => this.allBrands = data, err => console.log(err));


  }

  types: string[] = [];
  order_devices: OrderDevice[] = [];
  allModels: Model[] = [];
  allBrands: Brand[] = [];
  models: Model[] = [];
  brands: Brand[] = [];

  // clickCustomer(customer) {}

  logout(){
    localStorage.removeItem('token');
    this.baseService.USER = new User();
    this.router.navigateByUrl('/login');
  }

  closeW(){
    this.router.navigateByUrl('/new-order')
  }

  nextPage(){
    this.router.navigateByUrl('/order-form')
  }

  ngOnInit() {
    

  }

  addNewOrderDevice(evt) {
    let od = new OrderDevice();
    od.note.push(new Note());
    this.order.order_devices.push(od);
    this.current = this.order.order_devices.length - 1;
  }
  
  devicedChanged(evt) {

  }

  brandChanged(evt) {
    if (evt)
    {
      let same = this.order.order_devices[this.current].device.model.brand.id == evt.id;
      this.order.order_devices[this.current].device.model.brand = evt;

      if (!same)
      {
        this.order.order_devices[this.current].device.model.id = -1;
        this.order.order_devices[this.current].device.model.name = "";

      }
      if (evt.id == -1)
        this.models = [];
      else
        this.models = this.allModels.filter(x => x.brand.id == evt.id);

    }
    else
      this.order.order_devices[this.current].device.model.brand = new Brand();
    
  }

  modelChanged(evt) {
    if (evt)
    {
      let same = this.order.order_devices[this.current].device.model.id == evt.id;
      this.order.order_devices[this.current].device.model.id = evt.id;
      this.order.order_devices[this.current].device.model.name = evt.name;

      if (!same)
      {
        this.order.order_devices[this.current].services = [];
        this.order.order_devices[this.current].total = 0;
      }

      
      if (evt.name.trim() == '')
      {
        this.order.order_devices[this.current].device.type = '';
      }
      
    }
    else
      this.order.order_devices[this.current].device.model = new Model();
  }

  addService(evt)
  {
    this.order.order_devices[this.current].services.push(new Service());
    setTimeout(() => {
      let services = document.getElementById('services');

      services.scrollTop = services.scrollHeight;
      let x = services.lastElementChild.childNodes[1] as HTMLInputElement;
      console.log(x);
      x.focus();
      
    }, 1);
  }

  removeService(evt, pos)
  {
    let service = evt.target.parentNode.parentNode as HTMLDivElement;
    service.style.animation = 'fadeOut .3s forwards';
    setTimeout(() => {
      this.order.order_devices[this.current].services.splice(pos, 1);
    }, 300);
    console.log(pos);
  }

  enterServicePressed(evt, service: Service)
  {
    if (service.name.trim().length == 0)
      return;
    
    this.baseService.post('service/price', {model_id: this.order.order_devices[this.current].device.model.id, service_name: service.name}, true)
    .subscribe((data: any) => {
      service.price = data.price == "0" ? data.price + ".00" : data.price.includes('.') ?  data.price : data.price + ".00";
    },
    err => console.log(err));
  }


  isValidToAddService() {
    if (this.order.order_devices[this.current].device.model.name.trim() == '')
      return false;
    if (this.order.order_devices[this.current].services.length == 0)
      return true;
    let service = this.order.order_devices[this.current].services[this.order.order_devices[this.current].services.length - 1];
    return service.name.trim() != '' && service.price;
  }

  totalByOD = (pos: number) => this.order.order_devices[pos].services.sumBy(x => x.price);



  submit() {


    if(this.isSending)
      return;

    this.isSending= true;

    
    this.order.user = this.baseService.USER;
    console.log(this.order.user);

    for (let i= 0; i< this.order.order_devices.length; i++) {
      // this.order.order_devices[i].user = this.order.user;
      if (this.order.order_devices[i].note[0].message.trim() == '')
        this.order.order_devices[i].note = [];
      else
        this.order.order_devices[i].note[0].name = this.baseService.USER.name;
    }


    this.baseService.post('order', {order: JSON.stringify(this.order)}, true).subscribe((data: Order) => {
      // alert(`went true with id: ${data.id}`);
      Swal.fire(
        'Success',
        `This order has been placed with id: ${data.id}`,
        'success'
      ).then(d => this.isSending = false);

      this.router.navigateByUrl('/order-form/' + data.order_devices.map(x => x.id).join("&"));
      
    }, err => {
      // alert('error');
      Swal.fire(
        'Error',
        'Something went wrong',
        'error'
      ).then(d => this.isSending = false);;
      console.log(err);
    });
  }

  capitilaze(evt, service: Service) {
    // let text = service.name;
    // text = text.trim();

    // let text1 = '';
    // for (let i = 0; i < text.length; i++)
    // {
    //   if (i == 0)
    //     text1 = text.substring(0, 1).toUpperCase();
    //   else if (text.substring(i - 1, i) == ' ')
    //   {
    //     if (text.substring(i, i + 1) == ' ') i++;
    //     else text1 += text.substring(i, i + 1).toUpperCase();
    //   }
    //   else
    //   {
    //     text1 += text.substring(i, i + 1).toLowerCase();
    //   }
    // }
    service.name = service.name;
  }

  setDevice(device:Device){
    console.log(device);
    let d = JSON.clone(device)
    let temp = this.allBrands.find(x => x.name.trim().toLowerCase() == d.model.brand.name.trim().toLowerCase());
    let b_id = -1;
    if (temp)
      b_id = temp.id;
    d.model.brand.id = b_id;
    d.model.brand.name = d.model.brand.name.trim();
    
    this.order.order_devices[this.current].device.model.brand = d.model.brand;
      
    temp = this.allModels.find(x => x.name.trim().toLowerCase() == d.model.name.trim().toLowerCase());

    let m_id = -1;

    if (temp)
      m_id = temp.id;
    
    d.model.id = m_id;
    this.order.order_devices[this.current].device.model = d.model;

    this.order.order_devices[this.current].device.id = d.id;
    this.order.order_devices[this.current].device.type = d.type;
    this.order.order_devices[this.current].device.serial = d.serial;
    this.order.order_devices[this.current].device.color = d.color;
    this.order.order_devices[this.current].device.password = d.password;

      // this.order.order_devices[this.current].device.model 
    }

    removeOrderDevice(pos) {
      if (this.order.order_devices.length == 1)
        return;
      if (this.current == this.order.order_devices.length - 1)
        this.current--;
      this.order.order_devices.splice(pos, 1);
    }

    
  }


