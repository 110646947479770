<div class="buttons">
    <div class="actions" style="width: 50%;">

        <button class="btn btn-clear btn-0br no-padding" (click)='open()'>Create new <i class="fas fa-plus red"></i></button>
    </div>
    <!-- <div class="btn-select">
        <button class="btn btn-blue btn-0br" [disabled]='!selectedCustomer' (click)='selectCust()'>Select <i class="fas fa-check"></i></button>
    </div> -->
</div>
<!--Search Bar-->
<div class="search-b searching" id="search-b">
    <input [(ngModel)]='searchTerm' name="search" type="text" (input)="searchCustomer($event)" placeholder="Search Employee" id="search-input" autocomplete="off">
    <button class="btn btn-clear" style="padding: 0;" (click)="search($event)"><img style="width: 32px; height: 32px;" [src]="'../../../assets/imgs/' + (searchShown ? 'close.png' : 'search-icon.png')" alt=""></button>
</div>

<div class="modal-container" *ngIf='sheetShowed'>
    <div class="modal" id="modal-customer">
        <img style="height: 80px; display: block; margin-left: auto;" src="../../../assets/imgs/close.png" alt="" (click)='close()'>
        <div class="customer-box container">
            <div style="animation: fadeIn 1s forwards;">
                <form #form='ngForm' (ngSubmit)='submit(form)' class="fields">
                    <div class="field w-12">
                        <h2 style="font-size: 25px; margin-bottom: 20px;"> <img style="height: 20px;" src="../../../assets/imgs/user.png" alt=""> {{customer.id == -1 ? 'Add New Employee' : 'Add New Employee'}}</h2>
                    </div>
                    <div class="field w-6">
                        <label for="name">User Name</label>
                        <input style="text-transform: uppercase;" type="text" placeholder="User Name" name="name" id="name" [(ngModel)]='customer.name'>

                    </div>
                    <div class="field w-6">
                        <label for="level">Level</label>
                        <select class="select" name="level" id="level" style="margin-right: 40px;" [(ngModel)]='customer.level'>
                            <option *ngFor="let state of [0,2,9]" [value]= 'state'>{{state}}</option>
                        </select>
                    </div>

                    <p  style="margin-bottom: 20px; margin-top: -20px;" class="w-12 invalid-text" [ngStyle]="{'opacity': customer.name.trim().length > 0 ? '0' : '1'}">Name is required</p>

                    <div style="display: flex; width: 100%">
                        <div class="field w-6">
                            <label for="code">Password*</label>
                            <input style="text-transform: none;" type="text" placeholder="Password..." name='code' id="code" [(ngModel)]='code'>
                            <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.street.trim().length > 0 ? '0' : '1'}">Empty Field</p> -->
                        </div>

                        <div class="field w-6">
                            <label for="ccode">Confirm Password*</label>
                            <input style="text-transform: none;" type="password" placeholder="Confirm password..." name='ccode' id="ccode" [(ngModel)]='confirmCode'>
                            <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.city.trim().length > 0 ? '0' : '1'}">Empty Field</p> -->
                        </div>
                    </div>
                    <p  style="margin-bottom: 20px; text-align: center;" class="w-12 invalid-text" [ngStyle]="{'opacity': code.trim().length == 0 || code != confirmCode ? '1' : '0'}">{{code.trim() == '' ? 'PASSWORD IS REQUIRED' : 'PASSWORDS DOES NOT MATCH'}}</p>
                    <div class="container btn-container mobile-button">
                        <button type="submit" class="btn btn-big" style=" display: block; margin-left: auto; margin-bottom: 20px;" [disabled]='!isValid()'>SAVE</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="buttons" id="filters-1">
    <div class="buttons-1" id="filter">
        <button class="btn btn-outline space" [ngStyle]="{'opacity': selectedCustomers.length == 1 ? '1' : '0'}" (click)='selectCust(selectedCustomers[0])'>Select</button>
        <button class="btn btn-outline space" [ngStyle]="{'opacity': selectedCustomers.length >= 1 ? '1' : '0'}" (click)='export()'>Export to Excel</button>
        <input class="check-all" type="checkbox" [(ngModel)]="allSelected" (ngModelChange)="selectAll()">
    </div>
</div>

<div class="customer-table" id="print-section">
    <div class="header" id="header">
        <p (click)="sort('id')" [ngClass]="{'active': sortBy.name == 'id'}">ID<i *ngIf="sortBy.name == 'id'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p (click)="sort('customer')" [ngClass]="{'active': sortBy.name == 'customer'}">USER<i *ngIf="sortBy.name == 'customer'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p>LEVEL</p>
        <p>ACTIONS</p>
    </div>
    <hr>
    <div class="body" style="max-height: 70vh; overflow: scroll;">

        <cdk-virtual-scroll-viewport itemSize="51" style="width: 100%; height: 70vh;">

            <ng-container #table *cdkVirtualFor="let customer of customers; let i = index;">

                <div class="row">
                    <div class="column">
                        <p>{{customer.id}}</p>
                    </div>
                    <div class="column">
                        <p>{{customer.name}}</p>
                    </div>

                    <div class="column">
                        {{customer.level}}
                    </div>
                    <div class="column">
                        <button class="btn btn-red btn-0br p-6" (click)='deleteCustomer(customer)'><i class="fas fa-trash-alt"></i></button>
                        <button [routerLink]="'/clock/' + customer.id" class="btn" style="margin-left: 10px; background: #FFF; color: #000; border: 1px solid #000;">SEE CLOCK</button>
                    </div>
                    <div class="column line">
                        <input type="checkbox" [checked]='isCustSelected(customer)'>
                    </div>

                    <button class="full-button" [ngClass]="{'active' : isCustSelected(customer)}" (click)="toogleSelected(customer)"></button>
                    <!-- <button class="big" (click)='selectCust(customer)'></button> -->

                </div>
                <!-- <button class="btn btn-clear no-padding row-button" style="width: 100%; cursor: pointer;" (click)='selectCust(customer)'></button> -->

            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>