import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/base.service';

@Component({
  selector: 'app-archived-tickets',
  templateUrl: './archived-tickets.component.html',
  styleUrls: ['./archived-tickets.component.scss']
})
export class ArchivedTicketsComponent implements OnInit {

  orderDevices: any[] = [];
  constructor(private baseService: BaseService) { 
    baseService.post('order-device/true', {from_date: '1-1-2000', to_date: '12-31-2100'}, true).subscribe((data:any) => {
        this.orderDevices = data;
    })
  }

  ngOnInit() {
  }

}
