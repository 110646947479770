import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lapc-web';
  foot_date = new Date();
  constructor(private location: Location)
  {
    this.initArrays();
  }

  initArrays() {

    JSON.clone = obj  => JSON.parse(JSON.stringify(obj));
    Array.prototype.clone = function () {
      return JSON.parse(JSON.stringify(this));
    }

    Array.prototype.sumBy = function(params) {
      return (this.reduce((total, current) => total + (isNaN(params(current)) ? 0 : params(current) * 1000), 0) / 1000)
    };

    Array.prototype.groupBy = function (params) {
      return this.reduce((previous, current) => {
        const index = previous.findIndex(x => x.some(y => params(y) == params(current)));
        index > -1 ? previous[index].push(current) : previous.push([current]);
        return previous;
      }, []);
    }
  }

  checkRoute(path) {
    if (this.location.path().toLocaleLowerCase().startsWith(path.toLowerCase()))
      return true;
    return null;
  }
}
