import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { Router } from '@angular/router';

declare var $ : any;
@Component({
  
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})


export class NotFoundComponent implements OnInit {


  customers:any = [];
  constructor(public baseService: BaseService, private router: Router) {
    baseService.get("customer", true).subscribe((data: any) => {
      this.customers = data;
    });
  }

  // clickCustomer(customer) {}


  ngOnInit() {
    

}

callBack(){
  this.router.navigateByUrl('/open-tickets')
}
  

}
