import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../classes/classes';
import { Location } from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public BASE_URL = "https://lapc-mac.herokuapp.com/api/";
  //  public BASE_URL = "http://localhost:15000/api/";

  public USER:User = new User();

  userEmitter = new EventEmitter();

  constructor(private http: HttpClient, private location: Location) {
    if (location.path() != '/login')
      this.get('user/my-profile', true).subscribe((data: User) => {
        this.USER = data
        this.userEmitter.emit('user-loaded', data);
      });
  }

  onUserLoaded(callback) {

  }

  getHeaders(nToken = false) {
    let headers: HttpHeaders = new HttpHeaders();

  if (nToken)
  {
    headers = headers
    .append("Content-Type", "application/x-www-form-urlencoded")
    .append('Authorization', localStorage.getItem("token") || '');
  }
  else
  {
    headers = headers
      .append("Content-Type", "application/x-www-form-urlencoded");
  }

  return headers;

  }
  async refreshUser() {


  }
  get(subUrl, nToken = null)
  {
    let headers: HttpHeaders = new HttpHeaders();

  if (nToken)
  {
    headers = headers
    .append("Content-Type", "application/x-www-form-urlencoded")
    .append('Authorization', localStorage.getItem("token") || '');
  }
  else
  {
    headers = headers
      .append("Content-Type", "application/x-www-form-urlencoded");
  }

  return  this.http.get(this.BASE_URL + subUrl,
   {headers: headers});
  }

  delete(subUrl, nToken = null)
  {
    let headers: HttpHeaders = new HttpHeaders();

    if (nToken)
    {
      headers = headers
          .append("Content-Type", "application/x-www-form-urlencoded")
          .append('Authorization', localStorage.getItem("token") || '');
    }
    else
    {
      headers = headers
          .append("Content-Type", "application/x-www-form-urlencoded");
    }

    return  this.http.delete(this.BASE_URL + subUrl,
        {headers: headers});
  }

  post(subUrl, body, nToken = null)
  {
    let headers: HttpHeaders = new HttpHeaders();

  if (nToken)
  {
    headers = headers
    .append("Content-Type", "application/x-www-form-urlencoded")
    .append('Authorization',localStorage.getItem("token") || '');
  }
  else
  {
    headers = headers
      .append("Content-Type", "application/x-www-form-urlencoded");
  }

  return  this.http.post(this.BASE_URL + subUrl, this.formData(body),
   {headers: headers});
  }

  postEmail(subUrl, body)
  {
    let headers: HttpHeaders = new HttpHeaders();



    headers = headers
    // .append("Content-Type", "application/x-www-form-urlencoded")
    .append('enctype', 'multipart/form-data')
    .append('Authorization',localStorage.getItem("token") || '');

    return  this.http.post(this.BASE_URL + subUrl, body,
     {headers: headers});

  }

  formData(myFormData){
    return Object.keys(myFormData).map(function(key){
    return encodeURIComponent(key) + '=' + encodeURIComponent(myFormData[key]);
  }).join('&');

  }


  put<T>(subUrl, body, nToken = null): Promise<T>
  {
    let headers = this.getHeaders(nToken);

    return new Promise((resolve, reject) => {
      this.http.put<T>(this.BASE_URL + subUrl, this.formData(body),
       {headers: headers}).subscribe(d => resolve(d), err => reject(err));
    })
  }


  newPut(subUrl, body, nToken = null)
  {
    let headers: HttpHeaders = new HttpHeaders();

  if (nToken)
  {
    headers = headers
    .append("Content-Type", "application/x-www-form-urlencoded")
    .append('Authorization',localStorage.getItem("token") || '');
  }
  else
  {
    headers = headers
      .append("Content-Type", "application/x-www-form-urlencoded");
  }

  return  this.http.put(this.BASE_URL + subUrl, this.formData(body),
   {headers: headers});
  }

  post1<T>(subUrl, body, nToken = null): Promise<T>
  {
    let headers = this.getHeaders(nToken);

    return new Promise((resolve, reject) => {
      this.http.post<T>(this.BASE_URL + subUrl, this.formData(body),
       {headers: headers}).subscribe(d => resolve(d), err => reject(err));
    })
  }

  get1<T>(subUrl, nToken = null): Promise<T>
  {
    let headers = this.getHeaders(nToken);

    return new Promise((resolve, reject) => {
      this.http.get<T>(this.BASE_URL + subUrl,
       {headers: headers}).subscribe(d => resolve(d), err => reject(err));
    })
  }
}
