<app-navbar *ngIf="!checkRoute('/login') && !checkRoute('/order-form') && !checkRoute('/receipt');"></app-navbar>


<ng-container *ngIf="!checkRoute('/new-order') && !checkRoute('/404') && !checkRoute('/input-device') && !checkRoute('/order-detail') && !checkRoute('/login'); else other">

    <div class="container">
        <router-outlet></router-outlet>
    </div>
    <!-- sd -->
</ng-container>

<ng-template #other>
    <router-outlet></router-outlet>
</ng-template>

<app-fab-button *ngIf='!checkRoute("/login")'></app-fab-button>

<footer class="footer">
    <p><i class="fa fa-copyright" aria-hidden="true"></i> Copyright <strong>LAPC Networking Inc.</strong> All rights reserved {{foot_date | date:('yyyy')}}</p>
</footer>