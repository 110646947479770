import { Component, OnInit, ElementRef } from '@angular/core';
import { speedDialFabAnimations } from './fab-button.animation';
import { delay } from 'q';
import { Router } from '@angular/router';
import { BaseService } from '../../../services/base.service';
@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
  animations: speedDialFabAnimations
})
export class FabButtonComponent implements OnInit {

  fabButtons = [
    {
      icon: 'open-ticket2.png',
      url: 'open-tickets',
      name: 'Open'
    },
    {
      icon: 'archived-ticket2.png',
      url: 'archived-tickets',
      name: 'Archived'
    },
    {
      icon: 'customer2.png',
      url: 'customers',
      name: 'Customers'
    },
    {
      icon: 'employee2.png',
      url: 'employees',
      name: 'Employees'
    },
    {
      icon: 'terms-1.png',
      url: 'update-terms',
      name: 'Terms'
    },
    {
      icon: 'receipts-2.png',
      url: 'update-receipt',
      name: 'Receipt'
    },
    {
      url: 'clock',
      name: 'Clock',
      icon: 'clock1.png'
    },
    {
      icon: 'new-ticket2.png',
      url: 'new-order',
      name: 'New'
    },
  {
    icon: 'prices.jpg',
    url: 'prices',
    name: 'Prices'
  }
  ];
  buttons = [];
  fabTogglerState = 'inactive';

  constructor(private router: Router, private html: ElementRef, public baseService: BaseService) { }


  promptEvent;
  ngOnInit() {
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });
    
  }

  installPwa(): void {
    // this.Pwa.promptEvent.prompt();
  }


  showItems() {
    // document.body.style.opacity = ".1";
    // let tag = this.html.nativeElement as HTMLElement;
    // tag.style.opacity = "1";
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
    
  }

  hideItems() {
    // let tag = this.html.nativeElement as HTMLElement;
    // tag.style.opacity = "1";
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
    // this.fabButtons
    let fab = document.getElementById('fab');
    fab.classList.toggle('active', this.buttons.length > 0);
    document.getElementById("modal").style.opacity = this.fabTogglerState == "active" ? '1' : '0';
    document.getElementById("modal").style.display = this.fabTogglerState == "active" ? 'block' : 'none';
    document.body.style.overflow = this.fabTogglerState == "active" ? "hidden" : "unset";
  }

  navigate(url) {
    this.onToggleFab();
    // delay(1);
    if (url == 'clock')
      url += '/' + this.baseService.USER.id;
    this.router.navigateByUrl(url);
  }

  close() {
    this.onToggleFab();
  }
}
