import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, NgZone } from '@angular/core';
import * as moment from 'moment';
import { JsonPipe } from '@angular/common';
import { BaseService } from '../../../services/base.service';
import { OrderDetailComponent } from '../../../components/order-detail/order-detail.component';
import { identifierModuleUrl } from '@angular/compiler';

import { OrderDevice } from '../../../classes/classes';
import { Router } from '@angular/router';

import { Util } from '../../../util/util';
import { from } from 'rxjs';
@Component({
  selector: 'app-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss']
})
export class DeviceTableComponent implements OnInit, AfterViewInit {
  // [x: string]: any;

  selected: any;
  firstTime: boolean = true;
  reselectFilter1: boolean = false;
  public searchTerm: string = '';
  public orders_device: OrderDevice[] = [];
  pos : number = 0;
  allTypeSelected: boolean = true;
  @ViewChildren('table') things: QueryList<any>;
  @ViewChildren('filter') things1: QueryList<any>;
  original: OrderDevice[] = [];
  @Input() public isArchived: boolean = false;
  sortBy = {name: '', asc: false};
  // customerSelected: any;
  private input: HTMLInputElement;
  selectedOrderDevices: OrderDevice[] = [];
  constructor(private cdRef: ChangeDetectorRef, public baseService: BaseService, private router: Router, public util: Util, private ngZone: NgZone) {
    

  }

  toogleSelected(od: OrderDevice){
    if(this.isArchived){
      this.router.navigateByUrl('/order-detail/' + od.id);
    }
    else{
      let index = this.selectedOrderDevices.findIndex(x=> x.id == od.id);
      // console.log(index)
      if(index == -1)
        this.selectedOrderDevices.push(od);
      else 
        this.selectedOrderDevices.splice(index, 1);
    }
  }

  seeDetails(){
    // this.router.navigateByUrl('/order-detail/' + );
  }

  isOdSelected(od: OrderDevice){
    return this.selectedOrderDevices.findIndex(x=> x.id == od.id) != -1;
  }

  orderDetail(){
    this.router.navigateByUrl('/order-detail/' + this.selectedOrderDevices[0].id)
  }

  isValidToReceipt = () => this.selectedOrderDevices.length > 0 && this.selectedOrderDevices.filter(x=> x.status != "COMPLETED").length == 0;

  issueReceipt(evt){
    let ids = this.selectedOrderDevices.map(x=> x.id).join('&');
    this.router.navigateByUrl('/receipt/' + ids)
  }
  
  ngOnInit() {
    let now = new Date();
    now.setHours(23,59,59);
    let end = moment(now);
    let last:any = moment(now).add("-2", "months");
    let lastDate = new Date (last);
    lastDate. setHours(0,0,0);
    this.selected = {
      startDate: last,
      endDate: end
    }
    this.input = document.getElementById("search-input") as HTMLInputElement;
    this.baseService.post(`order-device/${this.isArchived}`, {from_date: last, to_date: now}, true).subscribe((data:OrderDevice[]) => 
    {
    this.orders_device = data;
  })
    
  }

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.check();
    })
    this.things1.changes.subscribe(t => {
      if (this.reselectFilter1){

        this.reselectFilter1 = false;
        this.allTypeSelected = true;
      }
    
    
    })
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  fillFilters1(){
      this.items = [];
      this.orders_device.forEach(od => {
        let index = this.items.findIndex(x => x.type.trim().toLowerCase() == od.device.type.trim().toLowerCase());//.filter(x => x.id == od.device.id) as Array<any>;
        if (index > -1)
        {
          this.items[index].count++;
        }
        else
        {
          this.items.push({
            id: od.device.id,
            type: od.device.type,
            count: 1,
            
          });
        }
  
      });
      let btn = document.getElementById ('btn-all') as HTMLButtonElement;
      if (btn) btn.classList.remove('btn-clear');
      this.allTypeSelected = true;
     
  }

    getTotalItem(){
      
      let total : number = 0;
      this.items.forEach(i => {
      total += Number (i.count);
      })
      return total;
    }


  check(): void {

    if (this.firstTime)
    {
      this.firstTime = false;
      this.original = JSON.parse(JSON.stringify(this.orders_device));
      this.fillFilters1();
      
    }
    
  }

  searchShown: boolean = false;
  items:any = [];
 

  getDiffTime(date) {
    let x = new Date(date);
    x.setHours(0, 0, 0);
    let x1 = new Date();
    x1.setHours(0, 0, 0);
    let d1 = moment(x);
    let d2 = moment(x1);
    
    let diff = d1.diff(d2, 'days');
    
    if (diff == 0) 
      return "(today)";
    else if (diff > 0)
      return `(in ${diff} day${diff == 1 ? '' : 's'})`
    else
      return `(${Math.abs(diff)} day${Math.abs(diff) == 1 ? '' : 's'} ago)`
  }

  async search(evt)
  {
    let div = document.getElementById("filters");
    div.classList.toggle("searching");
    this.searchShown = div.classList.contains("searching");
    this.searchTerm = this.searchShown ? this.searchTerm : '';
    this.orders_device = JSON.parse(JSON.stringify(this.original));
    this.fillFilters1();
    if (this.searchShown)
      this.input.focus();
  }

  change(evt, item = null) {
    let div = document.getElementById("left-container");
    let buttons = div.querySelectorAll("button");

    if (item && !evt.target.classList.contains("btn-clear"))
    {
      buttons[0].classList.remove("btn-clear");
      evt.target.classList.add("btn-clear");
    
      this.orders_device = JSON.parse(JSON.stringify(this.original));

      return;
    }
    else if (!item && !evt.target.classList.contains("btn-clear"))
      return;
    
    buttons.forEach(x => {
      x.classList.add("btn-clear");
    });
    evt.target.classList.remove("btn-clear");

  }


  change1(evt) {
    let div = document.getElementById("filters-1");
    let buttons = div.querySelectorAll("button");

    buttons.forEach(x => {
      x.classList.add("btn-outline");
    });
    evt.target.classList.remove("btn-outline");
  }

  //Events for filters
  applyFilters(){}

  applyFilter1(type?: string)
  {
    this.applyFilter (this.pos);
    if (type)
      this.orders_device = this.orders_device.filter(x => x.device.type.toLowerCase().trim() == type.toLowerCase().trim());

  }
  
  applyFilter(pos?:number){
    this.pos = pos || 0;
    if (pos == 1)
      this.orders_device = this.original.filter(x => x.status == 'IN PROGRESS' || x.status == 'UNASSIGNED');
    else if (pos == 2)
      this.orders_device = this.original.filter(x => x.status == 'COMPLETED');
    else 
      this.orders_device = JSON.parse(JSON.stringify(this.original));
  }

  applyFilter1Click(evt, item?){
    let div = document.getElementById("left-container");
    let buttons = div.querySelectorAll("button");

  
    if (item && !evt.target.classList.contains("btn-clear"))
    {
      buttons[0].classList.remove("btn-clear");
      evt.target.classList.add("btn-clear");
  
      this.applyFilter1();
      this.allTypeSelected = false;

      return;
    }
    else if (!item && !evt.target.classList.contains("btn-clear"))
      return;
    
    buttons.forEach(x => {
      x.classList.add("btn-clear");
    });
    evt.target.classList.remove("btn-clear");

    if (item)
      {
        this.applyFilter1(item.type);
      }
    else
      {
        this.orders_device = JSON.parse(JSON.stringify(this.original));
        this.allTypeSelected = false;
      }
  }
  applyFilterClick(evt, pos?:number){
    if (!this.isArchived)
    {
      this.applyFilter(pos);
      let btn = document.querySelectorAll('div#filter button');
      
      
      
      btn.forEach(x => {
        x.classList.add("btn-outline");
      });
      if (evt)
        evt.target.classList.remove("btn-outline");
      else 
       btn[0].classList.remove('btn-outline');
    }
    this.fillFilters1();
   
  }

  datesChanged(evt){
    let startDate = new Date (evt.startDate);
    let endDate = new Date(evt.endDate);
    endDate.setHours(23, 59, 59);
    if(evt.startDate && evt.endDate){
      this.baseService.post(`order-device/${this.isArchived}`, {from_date: startDate, to_date: endDate}, true).subscribe((data:any) => {
      this.original = data;
      this.orders_device = data;
      this.applyFilterClick(null, null);

      })
    }

  }

  searchInput(evt){
    let search = evt.target.value;
    // console.log(search);
  }

  searchDevice(evt) {
    let term:string = evt.target.value;
    term = term.toLowerCase().trim();
    
    // console.log(term);
    if (term.length == 0)
      this.orders_device = JSON.parse(JSON.stringify(this.original));
    else
      this.orders_device = this.original.filter(x => x.id.toString().indexOf(term) > -1 ||  x.order.id.toString().indexOf(term) > -1 ||  x.device.type.toLowerCase().trim().indexOf(term) > -1 ||  x.device.model.brand.name.toLowerCase().trim().indexOf(term) > -1 || x.device.model.name.toLowerCase().trim().indexOf(term) > -1 || x.order.customer.name.toLowerCase().trim().indexOf(term) > -1);
  }

  getDueColor(date: Date) {
    if(this.isArchived)
       return '#000000';
    else
    {
      return date < new Date()? '#000000': '#D41717';
    }

  }

  sort(name: string)
  {
    // console.log('HERe');
      if (this.sortBy.name == name)
        this.sortBy.asc = !this.sortBy.asc;
      else
      {
        this.sortBy.name = name;
        this.sortBy.asc = true;
      }
  
      console.log(this.sortBy);
  
      let temp = this.orders_device.clone();
      this.orders_device = [];
      switch (this.sortBy.name)
      {
        case 'id':
          // console.log('HERE');
            if (this.sortBy.asc)
              this.orders_device = temp.sort((x, y) => x.id > y.id ? 1 : -1);
            else
              this.orders_device = temp.sort((x, y) => x.id < y.id ? 1 : -1);
            break;
          case 'due':
            if (this.sortBy.asc)
              this.orders_device = temp.sort((x, y) => x.time_due > y.time_due ? 1 : -1);
            else
              this.orders_device = temp.sort((x, y) => x.time_due < y.time_due ? 1 : -1);
            break;
          case 'created':
            if (this.sortBy.asc)
              this.orders_device = temp.sort((x, y) => x.order.time > y.order.time ? 1 : -1);
            else
              this.orders_device = temp.sort((x, y) => x.order.time < y.order.time ? 1 : -1);
            break;
  
            case 'order':
              if (this.sortBy.asc)
                this.orders_device = temp.sort((x, y) => x.order.id > y.order.id ? 1 : -1);
              else
                this.orders_device = temp.sort((x, y) => x.order.id < y.order.id ? 1 : -1);
              break;
              case 'status':
                if (this.sortBy.asc)
                  this.orders_device = temp.sort((x, y) => x.status > y.status ? 1 : -1);
                else
                  this.orders_device = temp.sort((x, y) => x.status < y.status ? 1 : -1);
                break;
      }
      // console.log(this.orders_device.map(x => x.id));  
    
  }

  iconSide = () => this.sortBy.asc ? 'up' : 'down';  


  
}
