import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 403) {

                this.router.navigateByUrl('/login');
                console.log((err.error.message || err.statusText));
                localStorage.clear();
            }


            const error = err.error.message || err.statusText;

            return throwError(error);
        }))
    }
}