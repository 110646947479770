<div class="header">
    <div class="top container">
        <button class="btn btn-clear no-padding gray previous" (click)='closeW()'>
                <i class="fas fa-chevron-left"></i>
                Previous Page
        </button>

        <img *ngIf='inputDeviceShowed' src="../../../assets/imgs/close.png" alt="" (click)='inputDeviceShowed = false;'>
    </div>

    <div class="container bottom">
        <div class="row">
            <p>New Order</p>
        </div>
        <div class="row">
            <div style="display: flex; align-items: baseline;">
                <p style="margin-right: 5px;">Taken in by</p>
                <p>{{baseService.USER.name}}</p>
            </div>
            <!-- <div>
                <button class="btn btn-outline" style="font-weight: bold; margin-top: 7px;" (click)='logout()'>Switch User</button>
            </div> -->
        </div>
    </div>
</div>
<div *ngIf='inputDeviceShowed' style="animation: fadeIn 1s forwards;">

    <app-input-device [customer]='selectedCustomer'></app-input-device>
</div>

<div class="customer-box container">

    <div class="customer-box-header" *ngIf='!inputDeviceShowed'>
        <div>
            <p>Customer</p> <button class="btn btn-red" (click)='tableShowed = true;'><i class="fas fa-search"></i></button></div>
        <!-- <button class="btn btn-clear" (click)='clear()'>{{tableShowed ? 'Close' : 'Clear'}}</button> -->
    </div>
    <div *ngIf='tableShowed' style="animation: fadeIn 1s forwards;">
        <app-customer-table [isSearching]='true' (customerSelected)='customerSelected($event)'></app-customer-table>

    </div>



    <div class="customer-box-content" *ngIf='!tableShowed && !inputDeviceShowed && selectedCustomer.id !=-1' style="animation: fadeIn 1s forwards;">
        <div class="left">
            <div class="field w-12">
                <label for="">Customer Name</label>
                <input [(ngModel)]='selectedCustomer.name' readonly type="text" list="cust" autocomplete="true" placeholder="Customer Name" name="guest" [(ngModel)]="selectedCustomer.name" id="opt" />
            </div>

            <div class="field w-12">
                <div class="field w-6">
                    <label for="">Phone number (1)*</label>
                    <input [(ngModel)]='selectedCustomer.phone1' readonly type="text" placeholder="XXX-XXXX-XXX" [value]="selectedCustomer.phone1">

                </div>

                <div class="field w-6">
                    <label for="">Phone number (2)*</label>
                    <input [(ngModel)]='selectedCustomer.phone2' readonly type="text" placeholder="XXX-XXXX-XXX" [value]="selectedCustomer.phone2">
                </div>
            </div>

            <div class="field w-12">
                <label for="">Email Address</label>
                <input [(ngModel)]='selectedCustomer.email' readonly type="email" placeholder="abc@xyz.com" [value]="selectedCustomer.email">
            </div>
        </div>

        <div class="right">
            <div class="field w-12">
                <label for="">Street Addres*</label>
                <input [(ngModel)]='selectedCustomer.street' readonly type="text" placeholder="No Address" [value]="selectedCustomer.street">
            </div>

            <div class="field w-12">
                <label for="">City*</label>
                <input [(ngModel)]='selectedCustomer.city' readonly type="text" placeholder="No City Name" [value]="selectedCustomer.city">
            </div>

            <div class="field w-2">
                <label for="">State*</label>
                <input type="text" placeholder="No State" [(ngModel)]='selectedCustomer.state'>



            </div>
            <div class="field w-4">
                <label for="">Zip*</label>
                <input readonly type="text" placeholder="No Zip" readonly [(ngModel)]="selectedCustomer.zip">
            </div>
            <div class="field w-6">
                <label for="">Country*</label>
                <input readonly type="text" placeholder="No Country" [(ngModel)]='selectedCustomer.country'>

            </div>
            <div class="container" style="display: flex; justify-content: space-between;" *ngIf='!tableShowed'>
                <button *ngIf="baseService.USER.level >= 9" class="btn btn-big mb-12 " (click)="openJobAgreement()">SUBMIT JOB AGREEMENT</button>
                <button class="btn btn-big mb-12" (click)='inputDeviceShowed = !inputDeviceShowed' [disabled]='selectedCustomer.id == -1' *ngIf='!inputDeviceShowed && selectedCustomer.id != -1'>INPUT DEVICE INFO</button>
            </div>
        </div>

    </div>

    <div class="the-overlay" *ngIf="jobAgreementOpen">
        <div class="modal modal-medium">
            <div style="display: flex; justify-content: space-between; box-shadow: 0 3px 6px #00000029; padding: 20px 20px 20px 20px;">

                <h2 class="modal-title">Submit Job Agreement</h2>
                <div style="display: flex;">

                    <button class="btn btn-blue" style="margin-left: 6px;" (click)="closeJobAgreement()">Close</button>
                </div>
            </div>
            <div style="flex: 1; display: flex; overflow-y: auto; flex-direction: column; padding: 20px;">
                <textarea placeholder="Agreement..." [(ngModel)]="jobAgreement.value" style="width: 100%; height: 100%; padding: 20px;"></textarea>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 20px 20px 20px;">
                <button class="btn btn-red" (click)="closeJobAgreement()" style="width: 48%; padding: 10px 0;">Cancel</button>
                <button class="btn btn-blue" (click)="sumbitJobAgreement()" style="width: 48%; padding: 10px 0;">Submit</button>

            </div>
        </div>
    </div>

    <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.35); z-index: 999999;"
    *ngIf="loading">
    <div style="position: absolute; width: 90%; max-width: 512px; background: white; padding: 2rem; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 1rem;"
        (click)="$event.stopPropagation()">
        <img src="../../../assets/imgs/loading.gif"
            style="display: block; margin: 0 auto; width: 32px; height: 32px;">
        <h3 style="text-align: center; margin-top: 1rem;">PLEASE WAIT...</h3>
    </div>
</div>

</div>