import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


declare global {
  interface JSON {
    clone<T>(obj: T): T;
  }
  interface Array<T> {
    sumBy(predicate: (value: T) => number): number;
    clone(): Array<T>;
    groupBy(predicate: (value: T) => any): T[][];
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
