import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { Router } from '@angular/router';
import { Customer, Order, User } from '../../classes/classes';
import Swal from 'sweetalert2';

declare var $ : any;
@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})


export class NewOrderComponent implements OnInit {

  jobAgreement = {
    id: null,
    name: 'job_agreement',
    value: '',
  }
  loading = false;
  jobAgreementOpen = false;
  selectedCustomer: Customer = new Customer();
  tableShowed: boolean = false;
  inputDeviceShowed: boolean = false;
  order: Order = new Order();
  customers:any = [];
  constructor(public baseService: BaseService, private router: Router) {
    baseService.get("customer", true).subscribe((data: any) => {
      this.customers = data;
    });
    
    if (localStorage.getItem("customer"))
    {
      this.selectedCustomer = new Customer(JSON.parse(localStorage.getItem("customer")));
      this.selectedCustomer.state = this.selectedCustomer.state.trim();
      this.selectedCustomer.country = this.selectedCustomer.country.trim();
      this.selectedCustomer.zip = this.selectedCustomer.zip.trim();
     
      // console.log(this.selectedCustomer);
      // console.log("THERE'S A CUSTOMER")
      setTimeout(() => {
        localStorage.removeItem("customer");
      }, 1);
    }
    else
      this.tableShowed = true;
    // else
    // {
    //   let c = new Customer();
    //   c.id = 2992;
    //   c.name = 'TEST 1';
    //   c.phone1 = '222-222-2222';
    //   c.phone2 = '333-333-3333';
    //   c.email = 'mail@xyz.com';
    //   c.street = '1111 test';
    //   c.city = 'LA';
    //   c.state = 'CA';
    //   c.zip = '91111';
    //   c.country = 'United States';
    //   this.selectedCustomer = c;
    //   console.log("NOT THERE'S A CUSTOMER")
    // }
  }

  // clickCustomer(customer) {}

  logout(){
    localStorage.removeItem('token');
    this.baseService.USER = new User();
    this.router.navigateByUrl('/login');
  }

  clear() {
    // console.log("HERE");
    if (!this.tableShowed)
      this.selectedCustomer = new Customer();
    
  }
  closeW() {
    this.router.navigateByUrl('/customers')
  }

  nextPage() {
    this.router.navigateByUrl('/input-device')
  }

  ngOnInit() {

  }

  show() {

  }

  customerSelected(cust: Customer) {
    this.selectedCustomer = new Customer(cust);

    this.tableShowed = false;
  }


  openJobAgreement() {
    if (!this.selectedCustomer.email) {
      return Swal.fire({
        position: 'center',
        icon: 'error',
        title: `This customer doesn't have an email address, please add one in the customer's profile`,
      })
    }
    this.loading = true;
    this.baseService.get('terms-conditions', true).subscribe((data: any) => {
      this.jobAgreement = data.find(x => x.name == 'job_agreement');
      this.jobAgreementOpen = true;
      document.body.style.overflow = 'hidden';
      this.loading = false;
    }
    , err => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong',
      })
      this.loading = false;
    });
  }


  sumbitJobAgreement() {

    const data = {
      email_sent_to: this.selectedCustomer.email,
      customer_id: this.selectedCustomer.id,
      agreement: this.jobAgreement.value,
      customer_name: this.selectedCustomer.name,
    }

    this.loading = true;

    this.baseService.post("job-agreement", data, true).subscribe((data: any) => {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Job Agreement sent successfully',
      })
      this.router.navigateByUrl('/open-tickets')
    }, err=> {
      this.loading = false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong',
      })
    })
  }

  closeJobAgreement() {
    this.jobAgreementOpen = false;
    document.body.style.overflow = 'auto';
  }
}
