import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-terms',
  templateUrl: './update-terms.component.html',
  styleUrls: ['./update-terms.component.scss']
})
export class UpdateTermsComponent implements OnInit {

  terms_front: any = {};
  terms_back: any = {};
  data: any[] = [];

  constructor(public baseService: BaseService, private route:Router) {
    if (this.baseService.USER.level < 9)
      this.route.navigateByUrl('/open-tickets');
    baseService.get("terms-conditions", true).subscribe((data:any)=>{
      console.log(data);
      this.data = data;
      this.terms_front = data.find(x => x.name == 'front');
      this.terms_back = data.find(x => x.name == 'back');
      // console.log(this.terms_front);
      // console.log(this.terms_back);
  })
  }

  ngOnInit() {
  }

  async updateTerms(){
    try {
      await this.baseService.put<any>("terms-conditions",{terms: JSON.stringify(this.data)} ,true);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Terms and conditions have been edited',
        showConfirmButton: true,
        timer: 1500
      });

      setTimeout(() => {
        this.route.navigateByUrl('/open-tickets'); 
      }, 1500);     
      
    } catch (ex) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong',
        showConfirmButton: true,
        timer: 1500
      });
      // alert(JSON.stringify(ex));
      console.log(ex);
    }
    // console.log(this.terms_front);
  }

  isValid() {
    return this.terms_front.value != "" && this.terms_back.value != "";
  }

}
