import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomersComponent } from './components/customers/customers.component';
import { NewOrderComponent } from './components/new-order/new-order.component';
import { OpenTicketsComponent } from './components/open-tickets/open-tickets.component';
import { ArchivedTicketsComponent } from './components/archived-tickets/archived-tickets.component';
import { DeviceTableComponent } from './components/shared/device-table/device-table.component';
import { MatButtonModule } from '@angular/material';


import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FabButtonComponent } from './components/shared/fab-button/fab-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { CustomerTableComponent } from './components/shared/customer-table/customer-table.component';
import { InputDeviceComponent } from './components/shared/input-device/input-device.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ModalComponent } from './components/shared/modal/modal.component';
import { ComboboxComponent } from './components/shared/combobox/combobox.component';
import { ReceiptComponent } from './components/shared/receipt/receipt.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OrderFormComponent } from './components/shared/order-form/order-form.component';

// let options: Partial<IConfig> | (() => Partial<IConfig>);

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TimerComponent } from './components/shared/timer/timer.component';
import { UpdateTermsComponent } from './components/shared/update-terms/update-terms.component';
import {TokenInterceptor} from "./jwt.interceptor";
import { UpdateReceiptComponent } from './components/update-receipt/update-receipt.component';
import {CurrencyPipe} from "@angular/common";
import { EmployeesComponent } from './components/employees/employees.component';
import { MapComponent } from './components/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { PricesComponent } from './components/prices/prices.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    CustomersComponent,
    NewOrderComponent,
    OpenTicketsComponent,
    ArchivedTicketsComponent,
    DeviceTableComponent,
    FabButtonComponent,
    LoginComponent,
    OrderDetailComponent,
    CustomerTableComponent,
    InputDeviceComponent,
    NotFoundComponent,
    ModalComponent,
    ComboboxComponent,
    ReceiptComponent,
    OrderFormComponent,
    TimerComponent,
    UpdateTermsComponent,
    UpdateReceiptComponent,
    EmployeesComponent,
    MapComponent,
    PricesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScrollingModule,
    HttpClientModule,
    MatButtonModule,
    FormsModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    NgxDaterangepickerMd.forRoot(),
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAyFRqEtPgaaX4LKBhGndhqGVb80IrOzxo'
    }),
  ],
  providers: [
      CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
