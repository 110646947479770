<div class="modal" id="modal" (click)="close()">

</div>
<div class="fab-container">
    <!-- <button mat-fab class="fab-toggler" (click)="onToggleFab()"> -->
    <button id="fab" class="btn btn-fab" (click)='onToggleFab()'>
    <!-- <i class="material-icons">menu</i> -->
  
      <hr>
      <hr>
      <hr>
  </button>
    <div [@speedDialStagger]="buttons.length">
        <ng-container *ngFor="let btn of buttons">
            <button *ngIf="(btn.name != 'Terms' && btn.name != 'Receipt') || baseService.USER.level == 9" mat-mini-fab class="fab-secondary" color="secondary" (click)="navigate(btn.url)">
            <span>{{btn.name}}</span>
            <img style="width: 36; height: 36px; margin-top: -6px" src="../../../../assets/imgs/{{btn.icon}}" alt="">
          </button>
        </ng-container>

    </div>
</div>