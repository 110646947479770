import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { Order, OrderDevice, Charge, TaxCharge, Receipt, Customer } from 'src/app/classes/classes';
import { BaseService } from 'src/app/services/base.service';
import {CurrencyPipe, Location} from '@angular/common';
import dom2image from 'dom-to-image';
import htmlToImage from 'html-to-image';
import JSPDF from 'jspdf';
import { Util } from 'src/app/util/util';
import {delay} from 'q';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts'

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})

export class ReceiptComponent implements OnInit {

  // order: Order= new Order();
  // order_device: OrderDevice= new OrderDevice();
  current = -1;
  showMoney: boolean = true;
  order_devices: OrderDevice[] = [];
  issuedBy = '';
  taxCharges: TaxCharge[] = [];
  order: Order = new Order();
  receipt: Receipt = new Receipt();
  paymentMethods = ['CASH', 'CHECK', 'CARD'];
  printing: boolean = false;
  receipt_id = -1;
  customer: any;
  receipt_text: string = '';
  // Converting HTML to PDF File

    onExportClick(){
    const options = {
      margin: 1,
      filename: 'receipt.pdf',
      image: {type: 'png', quality: 2}, // png, webp
      html2canvas: {},
      jsPDF: { format: 'letter', orientation: 'portrait' },
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts:true,
      floatPrecision: 16 // or "smart", default is 16
    };
    
    const content: Element = document.getElementById('element-to-export');
    
    html2pdf()
    .from(content)
    .set(options)
    .save();

  }

  async printPdf(p) {

    if (p == null)

      if (this.receipt_id == -1)
      {
        this.receipt.grand_total = this.getGrandTotal();
        this.receipt.order_devices = this.order_devices;
        this.receipt.user = this.baseService.USER;
    
        try {
          
          let receipt = await this.baseService.post1<Receipt>('receipt', {receipt: JSON.stringify({
            ...this.receipt,
            receipt_time: moment(this.receipt.receipt_time).format('YYYY/MM/DD HH:mm:ss')
          })}, true);
          this.receipt_id = receipt.id;





          // this.router.navigateByUrl('/open-tickets');
          // alert('WENT TRUE: ' + receipt.id);
        } catch (ex) {
          return alert('EX ' + JSON.stringify(ex));
        }
      }
    this.send();
    // this.printing = true;
    // await this.util.delay(1);
    // let i = (await htmlToImage.toJpeg(document.getElementById('receipt'), {quality: 1, width: 912, height: 1180}));
    // // console.clear();
   

    
    // // doc.addImage(img1);
    // // console.log(img1);
    // // doc.movePage()
    
    // doc.addImage(img, 'JPEG', 0, 0, width, height);

    // if (p == 0)
    //   doc.save('test.pdf');
    // else
    // {
    //   var string = doc.output('datauristring');
    //   var embed = "<style> * { margin: 0; padding: 0; } </style> <img src='" + i + "'/>"
    //   var x = window.open("about:blank", "hello");
    //   x.document.write(embed);
    //   x.print();
    //   x.document.close();
    // }
    // this.printing = fa/
  }

  constructor(private router: Router, private currencyPipe: CurrencyPipe, public baseService: BaseService, private activatedRoute: ActivatedRoute, private location: Location, private util: Util) {

    let ids = activatedRoute.snapshot.paramMap.get('order_device_id');
    console.log(ids);

    baseService.get("terms-conditions", true).subscribe((data:any)=>{
      // console.log(data);
      const obj = data.find(x => x.name == 'receipt') || {value: ''};
      this.receipt_text = obj.value || '';
      // console.log(this.terms_front);
      // console.log(this.terms_back);
    })

    
    // alert(id)
    this.load(ids);
  
  }

  tax = '';
  

  async load(ids) 
  {
    
    // let self = this;
    // console.log(this.taxCharges); 

    let taxes = await this.baseService.get1<TaxCharge[]>('tax-charge', true);
    this.taxCharges = taxes.map(x => new TaxCharge(x));
    
    this.baseService.get("order-device/ids/" + ids, true).subscribe(async (data: OrderDevice[]) => {  
      
      console.log(data);
      this.issuedBy = data[0].order.user.name;
      this.order_devices = data;
      this.order_devices.map(x => {
        x.charges = [];
        for (let service of x.services)
        {
          let c = new Charge();
          c.name = service.name;
          c.price = service.price;
          c.quantity = 1;
          const t = taxes.find(x => +x.tax > 0);

          // console.log(t);
          
          c.tax_charge = t;
          c.total = c.price;
          // c.tax_charge = taxes.find(x => x.tax > 0);
          
          this.setTotalCharge(c);
          x.charges.push(c);
        }
      });
      this.order = this.order_devices[0].order;
      
      this.receipt.customer = this.order.customer;

      try {
        let r = await this.baseService.get1<Receipt>('receipt/order-device/' + ids, true); 

        console.log(r);
        this.order_devices = r.order_devices;
        this.receipt = r;
        this.receipt.receipt_time = new Date(r.receipt_time);
        this.receipt_id = r.id;
      } catch (ex) {
        // console.clear();
      }


    });
  }

  ngOnInit() {
    // document.addEventListener('click', (evt) => this.showMoney= true);
  }

  // clickCustomer(customer) {}
  closeW(){
    // this.location.back{}
    this.location.back();
  }

  print() {

    let win = window.open();
    // window.print();
    // dom2image
    dom2image.toPng(document.getElementById('element-to-export')).then(value => {
      let img = new Image();
      img.src = value;
      img.onload = () => {
        win.document.write(`<img src=${value}>`);
        win.print();
        win.close();
      }
    });
  }

  blur() {
    console.log('BLUR');
  }

  focus() {
    console.log('FOCUS');
  }

  taxChanged(evt, charge: Charge) {
    if (!evt)
      return;
    charge.tax_charge = evt;
    this.setTotalCharge(charge);
  }


  setTotalCharge(c: Charge)
  {
    if (c.tax_charge.id != -1)
    {
      
      let taxD = c.tax_charge.tax / 100;

      console.log(taxD);
      let plus = Number(c.price) * taxD;
      console.log(plus);
      c.total = Number(Number(Number(c.price) + plus).toFixed(2));
    }
    
  }

  modelChanged(evt) {
    console.log(evt);
  }

  inputBlur(evt) {
    let v = evt.target.value as string;
    console.log(v);
    if (v == '')
      v = '0';

      
      let d = v.split('.')[1];

      if (!d)
        d = '00';
      else if (d.length == 0)
        d = d + '00';
      else if (d.length < 2)
        d = d + '0';
      
      v = v.split('.')[0] + '.' + d;

      evt.target.value = v;
  }

  capitilaze(evt, charge) {
    // let text = charge.name;
    // text = text.trim();
    // console.log(text);

    // let text1 = '';
    // for (let i = 0; i < text.length; i++)
    // {
    //   if (i == 0)
    //     text1 = text.substring(0, 1).toUpperCase();
    //   else if (text.substring(i - 1, i) == ' ')
    //   {
    //     if (text.substring(i, i + 1) == ' ') i++;
    //     else text1 += text.substring(i, i + 1).toUpperCase();
    //   }
    //   else
    //   {
    //     text1 += text.substring(i, i + 1).toLowerCase();
    //   }
    // }
    charge.name = charge.name;
  }

  nameBlur(evt, charge) {
    evt.target.value = evt.target.value.trim();
    // console.log(evt);
    // console.log(charge);
    // charge.name = charge.name.trim();

  }

  updateCustomer(){
    this.baseService.post('customer', {customer: JSON.stringify(this.receipt.customer) }, true).subscribe((data:Customer) => {

    })

  }

  addCharge(charges: Charge[])
  {
    charges.push(new Charge());
  }

  removeCharge(charges: Charge[], pos: number)
  {
    charges.splice(pos, 1);
  }


  isValid() {
    if (this.receipt.id != -1)
      return true;
    let od = this.order_devices.filter(x => x.charges.filter(y => y.name != '' && y.tax_charge.id != -1).length == x.charges.length && x.charges.length >= 1).length;
    return od == this.order_devices.length && this.receipt.payment_method != '';
  }


  getPDF(skipImg = false) {
    const address = this.order.customer.street + '.\n' + this.order.customer.city + ', ' + this.order.customer.state + ' ' + this.order.customer.zip;
    const content =  [
      {
        image: !skipImg ? Util.LAPC_IMG : '',
        width: 140,
        height: 110,
        margin: [180, -10, 0, 20]
      },
      {
        margin: [25, 0, 0, 0],
        columns: [

          {
            text: [
              {text:`Created on: ${moment(this.receipt.receipt_time).format('MMM DD, YYYY hh:mm:ss A')}\n\n`, bold: true, fontSize:14},
              {text:'Receipt #' + this.receipt_id, bold:true, fontSize:20},
              {text:'\n\nIssue by: ', fontSize: 9},
              {text:this.receipt.user.name, bold:true, fontSize: 9},
              {text:' | Printed by: ', fontSize: 9},
              {text:this.baseService.USER.name, bold:true, fontSize: 9}
            ],

          },
          {
            text: [
              {text:'LAPC Networking Inc.\n', bold: true, fontSize:16},
              {text:'3609 W, Magnolia Blvd.,\n', fontSize:12},
              {text:' Burbank, CA 91505\n'},
              {text:'(818) 478-2222  info@lapcnetworking.com'}
            ],
            alignment: 'right',

          }
        ]
      },
      {
        margin: [25, 40, 0, 0],
        columns: [

          {
            text: [
              {text:'Customer\n\n', bold: true, fontSize:10},
              {text:`${this.order.customer.name} \n`, bold:true, fontSize:16},
              {text: (this.order.customer.city && this.order.customer.state && this.order.customer.zip && this.order.customer.street && this.order.customer.country)  ? address + '\n' : ``, fontSize:11},
              {text: `\n${this.order.customer.phone1} | ${this.order.customer.email}`, fontSize:10},

            ],

          },
          {
            margin: [60, 0 , 0 ,0],
            text: [
              {text:'PAYMENT METHOD\n\n', bold: true, fontSize:10},
              {text: this.receipt.payment_method + '\n', fontSize:12, color:"#000", bold:true},
              {text: this.receipt.reference + '\n', fontSize:12, color:"#000", bold:false},
            ]

          }
        ]
      },


      {
        margin: [25, 55, 0, 0],
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 500,
            h: 0,
            // lineWidth: 10,
            lineColor: 'black',
          }

        ]
      },
      {
        margin: [25, -15 , 0 ,0 ],
        columns: [
          {
            width: 80,
            text: [{text:"DEVICE #", bold:true, fontSize:9}]
          },
          {
            width: 140,
            text: [{text:"DESCRIPTION",  bold:true, fontSize:9}]

          },
          {
            width: 40,
            text: [{text:"QTY", bold:true, fontSize:9}]
          },
          {
            width: 80,
            text: [{text:"PRICE", bold:true, fontSize:9}]
          },
          {
            width: 80,
            text: [{text:"TAX", bold:true, fontSize:9}]
          },
          {
            text: [{text:"TOTAL", bold:true, fontSize:9}]
          }
        ]
      },
      this.order_devices.map((od, index) => {
        const obj = [];

        obj.push({
          margin: [25, 10, 0 ,0 ],
          columns: [
            {
              width: 80,
              text: [{text:"#" + od.id, bold:true, fontSize:10}]
            },
            {
              width: 140,
              text: [{text:od.device.model.name,  bold:true, fontSize:10}]

            }
          ]
        })

        od.charges.forEach((c, j) => {
          // obj.push({
          //   margin: [25, 3 , 0 ,0 ],
          //   columns: [
          //     {
          //       text: [{text:"#" + od.id, bold:true, fontSize:9}]
          //     },
          //     {
          //       margin: [-45, 0, 90, 0],
          //       text: [{text: od.device.type + "\n",  bold:true, fontSize:9} ]
          //
          //     },
          //     {
          //       margin: [100, 0, 0 , 0 ],
          //       text: [{text: c.quantity, bold:true, fontSize:9}]
          //     },
          //     {
          //       margin: [0, 0, 0 ,0 ],
          //       text: [{text: this.currencyPipe.transform(c.price, '$'), bold:true, fontSize:9}]
          //     },
          //     {
          //       margin: [-60,0 , 0 ,0],
          //       text: [{text: c.tax_charge.tax + '%', bold:true, fontSize:9}]
          //     },
          //     {
          //       margin: [-120, 0, 0, 0],
          //       text: [{text: this.currencyPipe.transform(c.total), bold:true, fontSize:9}]
          //     }
          //   ]
          // });

          obj.push({
            margin: [25, 3, 0 ,0 ],
            columns: [
              {
                width: 80,
                text: ''
              },
              {
                width: 140,
                text: [{text:c.name,  bold:true, fontSize:9}]

              },
              {
                width: 40,
                text: [{text: c.quantity, bold:true, fontSize:9}]
              },
              {
                width: 80,
                text: [{text:this.currencyPipe.transform(c.price, '$'), bold:true, fontSize:9}]
              },
              {
                width: 80,
                text: [{text:c.tax_charge.tax + '%', bold:true, fontSize:9}]
              },
              {
                text: [{text:this.currencyPipe.transform(c.total, '$'), bold:true, fontSize:9}]
              }
            ]
          })
        });

        obj.push({
          margin: [25, 10, 5 , 0],
          text: [{text: 'Device Total: ' + this.currencyPipe.transform(od.charges.sumBy(x => x.total), '$'), alignment: 'right', bold: true}]
        });
        return obj;
      }),

      {
        margin: [0, 25, 0 , 0],
        text: [{text: "GRAND TOTAL: ", alignment: "right"},
          {text:this.currencyPipe.transform(this.receipt.grand_total, '$'), bold:true, fontSize:15}
        ],



      },
      {
        margin: [0, 25, 0, 0],
        text: [{fontSize: 9, text:this.receipt_text, alignment: "center"}
        ]
      },
      {
        margin: [0, 10, 0, 0],
        text: [{fontSize: 24, text:`LAPC Networking Inc.`, alignment: "center", bold: true}
        ]
      },

    ];
    return content;
  }

  async send() {


    // console.log(JSON.stringify(this.getPDF()));

    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

    const docDefinition = {
      info: {title: `receipt_${this.receipt.id}`},
      content: this.getPDF()
    }

    pdfMake.createPdf(docDefinition).print();
  }

  getTotalCharge(charges: Charge[])
  {
    return charges.sumBy(x => x.total);
  }

  getGrandTotal = () => Number(Number(this.order_devices.sumBy(x => x.charges.sumBy(y => y.total))).toFixed(2));

  async sendMail()
  {
    let em= "";
    const { value: email } = await Swal.fire({
      title: 'Email Address',
      input: 'email',
      backdrop: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      inputPlaceholder: 'Enter your email address'
    })
    
    em=email;
    

     
         
    if (email) {
      
      try{
        const docDefinition = {
          info: {title: `receipt_${this.receipt.id}`},
          content: this.getPDF(true)
        }
        this.baseService.post('email/send-receipt', {attachment: JSON.stringify(docDefinition), email: em, customer_name: this.receipt.customer.name, receipt: JSON.stringify(this.receipt)}, true)
        .subscribe(d => {
          Swal.fire({
            title: 'Success!',
            text: 'The order has been send it',
            timer: 2000,
            backdrop: false,
            icon:'success'
          }        
          )
        }, err => {
          Swal.fire({
            title: 'Error',
            text: err.message || err,
            icon:'error'
          }        
          )
        })

       
     }
     catch(ex){
        console.log(ex);
     }
     
    }
    
  }

  isModalOpen(){
    
  }
}
