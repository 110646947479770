<a class="btnback" routerLink="/open-tickets">
    <i class="fas fa-chevron-left"></i> Open Tickets
</a>
<div class="receipt-container" id="receipt">
    <div class="row">
        <div class="column-top">
            <img class="logo" src="../../../../assets/imgs/lapc_logo.png" alt="">
        </div>
        <div class="column-top">
            <div class="row mb-114">
                <div class="column w-6">
                    <h4 class="mb-23">Created on: </h4>
                    <h2 class="mb-26">Receipt {{'______'}}</h2>
                    <p>Issued by: <strong>{{'______'}}</strong> | Printed by: <strong>{{baseService.USER.name}}</strong></p>
                </div>
                <div class="column w-6 text-right">
                    <h3 class="mb-20">LAPC Networking Inc.</h3>
                    <p class="mb-26">3609 W, Magnolia Blvd.<br>Burbank, CA 91505</p>
                    <p>(818) 478-2222 | www.lapcnetworking.com</p>
                </div>
            </div>
            <div class="row mb-80">
                <div class="column w-6">
                    <p class="title mb-17">CUSTOMER</p>
                    <!-- <input class="mb-20">{{receipt.customer.name}} -->
                    <input class="input-charge" value="Customer Name" type="text" style="font-weight: bold;">
<!--                    <p class="mb-26" *ngIf='receipt.customer.street != ""'>{{receipt.customer.street}}<br>{{receipt.customer.city}}, {{receipt.customer.state}} {{receipt.customer.zip}}</p>-->
                    <p>{{'000-000-0000'}}</p>
                </div>
                <div class="column w-6">
                    <p class="title mb-12">PAYMENT METHOD</p>
                    <div class="payment-methods mb-12">
                        <button class="btn btn-outline" [disabled]='true' style="opacity: 1 !important;" *ngFor='let pm of paymentMethods' [ngClass]="{'active':  false}" >{{pm}}</button>
                    </div>
                    <textarea class="reference" [readOnly]='true'  placeholder="Notes (Ref #, Check #, etc)"></textarea>
                </div>
            </div>

            <div class="row table">
                <div class="column">
                    <p class="title">DEVICE #</p>
                </div>
                <div class="column">
                    <p class="title">DESCRIPTION</p>
                </div>
                <div class="column">
                    <p class="title">QTY</p>
                </div>

                <div class="column">
                    <p class="title">PRICE</p>
                </div>
                <div class="column">
                    <p class="title">TAX</p>
                </div>
                <div class="column ">
                    <p class="title">TOTAL </p>
                </div>
            </div>
            <hr>


            <div class="total-container mt-20">
                <p>Grand total</p>
                <h2>{{0 | currency}}</h2>
            </div>

            <p *ngIf="receipt" class="mt-20" style="text-align: center; line-height: 18px;">
                <textarea [(ngModel)]="receipt.value"> </textarea>
                <br>
                <span style="font-weight: bold; font-size: 24px; display: inline-block; margin-top: 10px;">LAPC Networking Inc.</span>
            </p>
        </div>

    </div>
</div>

<div class="btn-container floating-buttons">
    <button class='btn btn-black btn-big' routerLink="/open-tickets">Back</button>
    <button class='btn btn-black btn-big' (click)="updateTerms()" [disabled]='receipt?.value?.trim().length == 0'>Save Changes</button>
</div>