<div class="buttons">
    <div class="actions" style="width: 50%;">

        <button class="btn btn-clear btn-0br no-padding" (click)='open()'>Create new <i class="fas fa-plus red"></i></button>
    </div>
    <!-- <div class="btn-select">
        <button class="btn btn-blue btn-0br" [disabled]='!selectedCustomer' (click)='selectCust()'>Select <i class="fas fa-check"></i></button>
    </div> -->
</div>
<!--Search Bar-->
<div class="search-b searching" id="search-b">
    <input [(ngModel)]='searchTerm' name="search" type="text" (input)="searchCustomer($event)" placeholder="Search Customer" id="search-input" autocomplete="off">
    <button class="btn btn-clear" style="padding: 0;" (click)="search($event)"><img style="width: 32px; height: 32px;" [src]="'../../../assets/imgs/' + (searchShown ? 'close.png' : 'search-icon.png')" alt=""></button>
</div>

<div class="modal-container" *ngIf='sheetShowed'>
    <div class="modal" id="modal-customer">
        <img style="height: 80px; display: block; margin-left: auto;" src="../../../assets/imgs/close.png" alt="" (click)='close()'>
        <div class="customer-box container">
            <div style="animation: fadeIn 1s forwards;">
                <form #form='ngForm' (ngSubmit)='submit(form)' class="fields">
                    <div>
                        <h2 style="font-size: 25px; margin-bottom: 20px;"> <img style="height: 20px;" src="../../../assets/imgs/user.png" alt=""> {{customer.id == -1 ? 'Add New Customer' : 'Update Customer'}}</h2>
                    </div>
                    <div class="field w-12">
                        <label for="">Customer Name</label>
                        <input style="text-transform: none;" type="text" placeholder="Customer Name" name="name" [(ngModel)]='customer.name'>
                        <p class="invalid-text" [ngStyle]="{'opacity': customer.name.trim().length > 0 ? '0' : '1'}">Name is required</p>
                    </div>

                    <div class="phone">
                        <div class="field w-6">
                            <label for="">Phone (1)</label>
                            <input type="text" mask="000-000-0000" [dropSpecialCharacters]='false' placeholder="000-000-0000" name='phone1' [(ngModel)]='customer.phone1'>
                            <p class="invalid-text" [ngStyle]="{'opacity': customer.phone1.trim().length > 0 ? '0' : '1'}">Phone is required</p>
                        </div>

                        <div class="field w-6">
                            <label for="">Phone (2)</label>
                            <input type="text" mask="000-000-0000" [dropSpecialCharacters]='false' placeholder="000-000-0000" name='phone2' [(ngModel)]='customer.phone2'>
                        </div>
                    </div>

                    <div class="field w-12">
                        <label for="">Email</label>
                        <input style="text-transform: none;" type="email" placeholder="abc@xyz.com" name='email' [(ngModel)]='customer.email'>
                        <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.email.trim().length > 0 ? '0' : '1'}">Email is required</p> -->
                    </div>
                    <div style="display: flex;">
                        <div class="field w-12">
                            <label for="">Street address</label>
                            <input style="text-transform: none;" type="text" placeholder="No Address" name='street' [(ngModel)]='customer.street'>
                            <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.street.trim().length > 0 ? '0' : '1'}">Empty Field</p> -->
                        </div>

                        <div class="field w-12">
                            <label for="">City</label>
                            <input style="text-transform: none;" type="text" placeholder="No City Name" name='city' [(ngModel)]='customer.city'>
                            <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.city.trim().length > 0 ? '0' : '1'}">Empty Field</p> -->
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div class="field w-6">
                            <label for="">State</label>
                            <select class="select" name="state" id="" style="margin-right: 40px;" [(ngModel)]='customer.state'>
                                <option *ngFor="let state of states" [value]= 'state'>{{state}}</option>
                            </select>
                        </div>
                        <div class="field w-3" style="margin-left: 20px;">
                            <label for="">Zip</label>
                            <input type="text" placeholder="No Zip" mask='00000' name='zip' [(ngModel)]='customer.zip'>
                            <!-- <p class="invalid-text" [ngStyle]="{'opacity': customer.zip.trim().length > 0 ? '0' : '1'}">Empty Field</p> -->
                        </div>
                        <div class="field w-3">
                            <label for="">Country</label>
                            <input style="font-weight: bold; font-size: 20px; text-transform: none;" type="text" name="country" id="" placeholder="No Country" readonly [(ngModel)]='customer.country'>
                        </div>
                    </div>
                    <div class="container btn-container mobile-button">
                        <button type="submit" class="btn btn-big" style=" display: block; margin-left: auto; margin-bottom: 20px;" [disabled]='!isValid()'>SAVE</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="buttons" id="filters-1">
    <div class="buttons-1" id="filter">
        <button class="btn btn-outline space" [ngStyle]="{'opacity': selectedCustomers.length == 1 ? '1' : '0'}" (click)='selectCust(selectedCustomers[0])'>Select</button>
        <button class="btn btn-outline space" [ngStyle]="{'opacity': selectedCustomers.length >= 1 ? '1' : '0'}" (click)='export()'>Export to Excel</button>
        <input class="check-all" type="checkbox" [(ngModel)]="allSelected" (ngModelChange)="selectAll()">
    </div>
</div>

<div class="customer-table" id="print-section">
    <div class="header" id="header">
        <p (click)="sort('id')" [ngClass]="{'active': sortBy.name == 'id'}">ID<i *ngIf="sortBy.name == 'id'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p (click)="sort('customer')" [ngClass]="{'active': sortBy.name == 'customer'}">CUSTOMER<i *ngIf="sortBy.name == 'customer'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p>PHONENUMBER</p>
        <p>ADDRESS</p>
        <p>ACTIONS</p>
    </div>
    <hr>
    <div class="body" style="max-height: 70vh; overflow: scroll;">

        <cdk-virtual-scroll-viewport itemSize="51" style="width: 100%; height: 70vh;">

            <ng-container #table *cdkVirtualFor="let customer of customers; let i = index;">

                <div class="row">
                    <div class="column">
                        <p>{{customer.id}}</p>
                    </div>
                    <div class="column">
                        <p>{{customer.name}}</p>
                        <p class="viewMobile">{{customer.phone1}}</p>
                    </div>
                    <div class="column">
                        <p>{{customer.phone1}}</p>
                    </div>
                    <div class="column">
                        <p [ngStyle]="{'color' : customer.street == '' ? 'red' : ''}">{{customer.street == '' ? 'No address' : customer.street}}</p>
                        <p *ngIf="customer.street != ''">{{customer.city != '' ? customer.city + "," : ""}} {{customer.state}} {{customer.zip}}</p>
                    </div>
                    <div class="column">
                        <button class="btn btn-0br btn-blue p-6" (click)='updateCustomer(customer)'><i class="fas fa-pen"></i></button>
                        <button class="btn btn-red btn-0br p-6" (click)='deleteCustomer(customer)'><i class="fas fa-trash-alt"></i></button>
                    </div>
                    <div class="column line">
                        <input type="checkbox" [checked]='isCustSelected(customer)'>
                    </div>

                    <button class="full-button" [ngClass]="{'active' : isCustSelected(customer)}" (click)="toogleSelected(customer)"></button>
                    <!-- <button class="big" (click)='selectCust(customer)'></button> -->

                </div>
                <!-- <button class="btn btn-clear no-padding row-button" style="width: 100%; cursor: pointer;" (click)='selectCust(customer)'></button> -->

            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>