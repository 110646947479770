<nav>
    <div class="container">
        <div class="left">
            <a routerLink="open-tickets" routerLinkActive="active"><img routerLinkActive="active"
                    src="../../../assets/imgs/open-ticket.png" alt=""></a>
            <a routerLink="archived-tickets"><img routerLinkActive="active"
                    src="../../../assets/imgs/archived-ticket.png" alt=""></a>
            <a routerLink="customers"><img routerLinkActive="active" src="../../../assets/imgs/customer.png" alt=""></a>
            <a routerLink="employees" *ngIf="baseService.USER.level == 9"><img routerLinkActive="active"
                    src="../../../assets/imgs/employees.png" alt=""></a>
            <a routerLink="update-terms" *ngIf='baseService.USER.level == 9'><img routerLinkActive="active"
                    src="../../../assets/imgs/terms.png" alt=""></a>
            <a routerLink="update-receipt" *ngIf='baseService.USER.level == 9'><img routerLinkActive="active"
                    src="../../../assets/imgs/receipts.png" alt=""></a>


            <a *ngIf="baseService.USER.id > 0" [routerLink]="getRoute()"><img routerLinkActive="active"
                    src="../../../assets/imgs/clock.png" alt=""></a>


            <a routerLink="prices">
                <div routerLinkActive="active"
                    style="height: 64px; width: 64px; justify-content: center; display: flex; align-items: center;">
                    <img style="width: 48px; height: 48px;" src="../../../assets/imgs/prices2.jpg" alt="" />
                </div>
            </a>

            <a routerLink="new-order"><img routerLinkActive="active" src="../../../assets/imgs/new-ticket.png"
                    alt=""></a>
            <a routerLinkActive="active" routerLink="order-detail/{{order_device_id}}"
                *ngIf="order_device_id">#{{order_device_id}}</a>
        </div>

        <div class="cnt" style="align-items: baseline; display: flex; width: 100%;">
            <div class="center">
                <p [innerHTML]='getCurrentPage()'></p>
            </div>

            <div class="right">
                <p>{{baseService.USER.name}}</p>
                <select class="select" id="user-select" (change)='userOptionsChanged($event)' style="cursor: pointer;">
                    <option value="1">&nbsp;&nbsp;{{baseService.USER.name}}&nbsp;&nbsp;</option>

                    <option *ngIf="baseService.USER.level == 9" value="5">&nbsp;&nbsp;Manage Amazon Products&nbsp;&nbsp;
                    </option>
                    <option *ngIf="baseService.USER.level == 9" value="6">&nbsp;&nbsp;Manage Job Agreement&nbsp;&nbsp;
                    </option>
                    <option value="2">&nbsp;&nbsp;Switch User&nbsp;&nbsp;</option>
                    <!-- <option value="3">&nbsp;&nbsp;Add Technician&nbsp;&nbsp;</option> -->
                    <option value="4">&nbsp;&nbsp;Log Out&nbsp;&nbsp;</option>
                </select>
            </div>
        </div>
    </div>

    <div class="the-overlay" *ngIf="amazonOpen">
        <div class="modal">
            <div style="display: flex; justify-content: space-between; box-shadow: 0 3px 6px #00000029; padding: 20px 20px 20px 20px;">

                <h2 class="modal-title">Amazon Products</h2>
                <div style="display: flex;">

                    <button class="btn" (click)="addProduct()">Add New</button>
                    <button class="btn btn-blue" style="margin-left: 6px;" (click)="closeAmazon()">Close</button>
                </div>
            </div>

            <div style="flex: 1; display: flex; overflow-y: auto; flex-direction: column; padding: 20px;">
                <div style="display: flex; margin-top: 12px; align-items: center; border-bottom: 1px solid gray; padding-bottom: 8px;"
                    *ngFor="let p of amazonProducts; let i = index">
                    <div style="display: flex; flex: 1; flex-direction: column;">
                        <div style="margin-bottom: 12px;">
                            <img src="{{p.image}}" alt=""
                                style=" height: 64px; max-width: 100%; object-fit: contain; display: block; margin: 0 auto;">
                            <p style="margin-top: 12px;">{{p.title}}</p>
                        </div>
                        <div style="gap: 20px; display: flex;">

                            <a href="{{p.url}}" target="_blank" class="btn btn-blue"
                                style="width: 33%; text-align: center; text-decoration: none; display: inline-block;">Shop
                                {{p.price | currency}}</a>

                            <button (click)="rescrapeProduct(p)" class="btn btn-outline"
                                style="width: 33%;">Rescrape</button>
                            <button (click)="deleteProduct(p)" class="btn btn-red" style="width: 33%;">Delete</button>
                        </div>

                    </div>
                    <div style="display: flex; flex-direction: column;">


                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="the-overlay" *ngIf="jobAgreementOpen">
        <div class="modal modal-medium">
            <div style="display: flex; justify-content: space-between; box-shadow: 0 3px 6px #00000029; padding: 20px 20px 20px 20px;">

                <h2 class="modal-title">Job Agreement</h2>
                <div style="display: flex;">

                    <button class="btn btn-blue" style="margin-left: 6px;" (click)="closeJobAgreement()">Close</button>
                </div>
            </div>
            <div style="flex: 1; display: flex; overflow-y: auto; flex-direction: column; padding: 20px;">
                <textarea placeholder="Agreement..." [(ngModel)]="jobAgreement.value" style="width: 100%; height: 100%; padding: 20px;"></textarea>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 20px 20px 20px;">
                <button class="btn btn-red" (click)="closeJobAgreement()" style="width: 48%; padding: 10px 0;">Cancel</button>
                <button class="btn btn-blue" (click)="saveJobAgreement()" style="width: 48%; padding: 10px 0;">Save</button>

            </div>
        </div>
    </div>

    <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.35); z-index: 999999;"
        *ngIf="loading">
        <div style="position: absolute; width: 90%; max-width: 512px; background: white; padding: 2rem; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 1rem;"
            (click)="$event.stopPropagation()">
            <img src="../../../../assets/imgs/loading.gif"
                style="display: block; margin: 0 auto; width: 32px; height: 32px;">
            <h3 style="text-align: center; margin-top: 1rem;">PLEASE WAIT...</h3>
        </div>
    </div>

</nav>