import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, Input, ChangeDetectorRef } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import * as moment from 'moment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit {

  selected: any;
  firstTime: boolean = true;
  reselectFilter1: boolean = false;
  public customers: any = [];
  pos : number = 0;
  allTypeSelected: boolean = true;
  @ViewChildren('table') things: QueryList<any>;
  @ViewChildren('filter') things1: QueryList<any>;
  original: any = [];
  @Input() public isArchived: boolean = false;

  constructor(private cdRef: ChangeDetectorRef, public baseService:BaseService) {
    
  }
  
  ngOnInit() {
    let now = new Date();
    now.setHours(23,59,59);
    let end = moment(now);
    let last:any = moment(now).add("-1", "week");
    let lastDate = new Date (last);
    lastDate. setHours(0,0,0);
    this.selected = {
      startDate: last,
      endDate: end
    }

    this.baseService.get(`customer`, true).subscribe((data:any) => {
    this.customers = data;
  })
    
  }

  ngAfterViewInit() {}

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }


    getTotalItem(){
      
      let total : number = 0;
      this.items.forEach(i => {
      total += Number (i.count);
      })
      return total;
    }


  searchShown: boolean = false;
  items:any = [];
 

  getDiffTime(date) {
    let d1 = moment(date);
    let d2 = moment(new Date());
    let diff = d1.diff(d2, 'days');
    if (diff == 0) 
      return "(today)";
    else if (diff > 0)
      return `(in ${diff} days)`
    else
      return `(${Math.abs(diff)} days ago)`
  }

  async search(evt)
  {
    let div = document.getElementById("left-container");

    div.classList.toggle("searching");
    this.searchShown = div.classList.contains("searching");
    

  }

  change1(evt) {
    let div = document.getElementById("filters-1");
    let buttons = div.querySelectorAll("button");

    buttons.forEach(x => {
      x.classList.add("btn-outline");
    });
    evt.target.classList.remove("btn-outline");
  }

}
