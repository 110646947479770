import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import * as jspdf from 'jspdf';
import {Order, OrderDevice, Customer, Service} from 'src/app/classes/classes';
import { BaseService } from 'src/app/services/base.service';
import {CurrencyPipe, Location} from '@angular/common';
import dom2image from 'dom-to-image';
import { User } from '../../../classes/classes';
import htmlToImage from 'html-to-image';
import JSPDF from 'jspdf';
import { delay } from 'q';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {Util} from "../../../util/util";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {

  d: Date= new Date();
  users: User[] = [];
  order: Order = new Order();
  order_devices: OrderDevice [] = [];
  totalServices: number = 0;
  isprintig:boolean = false;
  terms_front: any = {};
  terms_back: any = {};
  data: any[] = [];
  
  constructor(private baseService: BaseService, private route: Router, private activatedRoute: ActivatedRoute, private currencyPipe: CurrencyPipe) {

    baseService.get("terms-conditions", true).subscribe((data:any)=>{
      // console.log(data);
      this.data = data;
      this.terms_front = data.find(x => x.name == 'front');
      this.terms_back = data.find(x => x.name == 'back');
      // console.log(this.terms_front);
      // console.log(this.terms_back);
  });
    
    baseService.get("user", true).subscribe((data: any) => {
      this.users = data;
      // console.log(data);
    });
    
    
    let ids = (activatedRoute.snapshot.paramMap.get('order_device_id'));
    // console.log(ids);
    baseService.get("order-device/ids/" + ids, true).subscribe((data: OrderDevice[]) => {  
      

      this.order_devices= data;    

  
      // console.log(data);
    
      
    });
  }

  ngOnInit() {
  }

  async printPdf(save: boolean = true){

    this.isprintig= true;
    await delay(1);
    var doc = new JSPDF("p", "mm", "a4", 0);
    let img= new Image();
    let terms = await htmlToImage.toJpeg(document.getElementById('terms'), {quality: 1, width: 912, height: 1180});
    let imgTerms = new Image();
    imgTerms.src = terms;
    imgTerms.width = 912;
    imgTerms.height = 1180;
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    // let win = window.open();
    // console.log('isnull', win == null);
    // await delay(4000);
    // console.log('isnull', win.document == null);
    
    for(let j=0; j<this.order_devices.length; j++)
    {  
      let i = (await htmlToImage.toJpeg(document.getElementById(`od-${j}`), {quality: 1, width: 912, height: 1180}));
      
      img.src = i;
      doc.addImage(img, 'JPEG', 0, 0, width, height);
      doc.addPage();
      doc.addImage(imgTerms, 'JPEG', 0, 0, width, height);
      img.width = 912;
      img.height = 1180;
      // win.document.write(`<img src='${i}' style='width: 912px; height: 1180px;'>`);
      // await delay(1);
      // win.document.write(`<img src='${terms}' style='width: 912px; height: 1180px;'>`);
      // await delay(1);

      if (j < this.order_devices.length - 1)
        doc.addPage();
        await delay(1);
    }
    
    // doc.autoPrint({var});  // <<--------------------- !!
      // window.open(doc.output('dataurlnewwindow'));
      if (save)
        doc.save('document_' + this.order_devices.map(x => x.id).join('_') + '.pdf');
      else
        return doc.output('blob');

    // this.route.navigateByUrl('/open-tickets');


    // try {
    //   // Print for Safari browser
    //   // win.document.execCommand('print', false, null)
    // } catch(ex) {
    //   console.log('ex', ex);
    // }
    // win.print();
    // win.close();
    
    this.isprintig= false;
    // window.open(doc.output('bloburl'), '_blank');
    // doc.autoPrint();  // <<--------------------- !!
    // doc.output('dataurlnewwindow');

    
  }

  sendPdf(){
    
  }

  async sendMail()
  {
    let em= "";
    Swal.fire({
      title: 'Email Address',
      input: 'email',
      backdrop: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      inputPlaceholder: 'Enter your email address'     
  }).then(async (result) => {
      if (result.value) {
          em= result.value;
          try{


            const definitions = JSON.parse(JSON.stringify([...this.order_devices])).map(x => ({
              content: this.getFirstPage(x, true, true),
              info: {title: `order_device_${x.id}`}
            }));

            const total = this.order_devices.sumBy(x => x.services.sumBy(y => y.price));

            let customer_name = this.order_devices[0].order.customer.name;
             this.baseService.post('email/send-order-device', {attachment: JSON.stringify(definitions), email: em, customer_name: customer_name, order_id: this.order_devices[0].id, total}).subscribe(
               data => {
                 console.log(data);
                 if (data) {
                   Swal.fire({
                     title: 'Success!',
                     text: 'The order has been send it',
                     timer: 2000,
                     backdrop: false,
                     icon:'success'
                   })
                 }
               },
               err=> {
                 console.log(err);
                 if(err){
                   Swal.fire({
                     title: 'Upps :(',
                     text: 'Something went wrong',
                     timer: 2000,
                     backdrop: false,
                     icon: 'error'
                   })
                 }
               }
             )
     
     
          }
          catch(ex){
             console.log('exeception ',ex);
          }

      }
  });
    

    
    
         
    
    
  }

  updateName(evt, charge) {
    evt.target.value = evt.target.value.trim();

  }

  updateCustomer(cust){
    this.baseService.post('customer', {customer: JSON.stringify(cust) }, true).subscribe((data:Customer) => {

    })

  }

  isModalOpen(){
    
  }

  getFirstPage(od: OrderDevice, last: boolean, skip: boolean = false) {

    const services = od.services.splice(0, 5);

    while (services.length < 5)
    {
      services.push(new Service());
    }

    const address = od.order.customer.street + '.\n' + od.order.customer.city + ', ' + od.order.customer.state + ' ' + od.order.customer.zip;
    return [{
      image: !skip? Util.LAPC_IMG : '',
      width: 140,
      height: 110,
      margin: [180, -10, 0, 20]
    },
        {
          margin: [0, 0, 0, 0],
          columns: [

            {
              text: [
                {text:`Created on: ${moment(od.order.time).format('MMM DD, YYYY hh:mm:ss A')}\n\n`, bold: true, fontSize:14},
                {text:'#' + od.id, bold:true, fontSize:20},
                {text:`\n\n`, bold:true},
                {text:'Taken in by: '},
                {text:'' + od.order.user.name, bold:true}
              ],

            },
            {
              text: [
                {text:'LAPC Networking Inc.\n', bold: true, fontSize:16},
                {text:'\n3609 W, Magnolia Blvd.,\n', fontSize:12},
                {text:' Burbank, CA 91505\n\n'},
                {text:'(818) 478-2222 | www.lapcnetworking.com'}
              ],
              alignment: 'right',

            }
          ]
        },
        {
          margin: [20, 10, 0, 0],
          columns: [

            {
              text: [
                {text:'Customer\n\n', bold: true, fontSize:10},
                {text:`${od.order.customer.name} \n`, bold:true, fontSize:16},
                {text: (od.order.customer.city && od.order.customer.state && od.order.customer.zip && od.order.customer.street && od.order.customer.country)  ? address + '\n' : ``, fontSize:11},
                {text: `\n${od.order.customer.phone1} | ${od.order.customer.email}`, fontSize:10},

              ],
              margin: [-15, 0, 0, 0]

            },
            {
              margin: [60, 0 , 0 ,0],
              text: [
                {text:'NOTES\n\n', bold: true, fontSize:10},
                {text: od.note.length > 0 ? od.note[0].message : '', fontSize:8},
              ]

            }
          ]
        },
        {
          margin: [20, 25, 0, 0],
          columns: [

            {
              text: [
                {text:'DEVICE\n\n', bold: true, fontSize:10},
                {text:`${od.device.type} ${od.device.model.brand.name} ${od.device.model.name}\n\n`, bold:true, fontSize:16},
                {text:`Color: ${od.device.color}\n`, fontSize:11},
                {text:`Serial: ${od.device.serial}\n`, fontSize:11},
                {text:`Accessories: ${od.accessorie}\n\n`, fontSize:10},

              ],
              margin: [-15, 0, -60, 0]

            },
            {

            }
          ]
        },
        {
          margin: [5, 25, 0, 0],
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 490,
              h: 0,
              // lineWidth: 10,
              lineColor: 'black',
            }

          ]
        },
        {
          margin: [5, -20 , 0 ,0 ],
          columns: [
            {
              text: [{text:"SERVICE DESCRIPTION", bold:true}]
            },
            {
              margin: [0, 0 , 20, 0],
              text: [{text:"TOTAL", alignment: 'right', bold:true}],

            }
          ]
        },
          services.map((x, i) => {

            const columns = {
              margin: [5, i == 0 ? 10 : 0 , 0 ,0 ],
              columns: [
                {
                  text: [{text:x.name}]
                },
                {
                  margin: [0, 0 , 20, 0],
                  text: [{text: x.id == -1 ? '\n' : this.currencyPipe.transform(x.price, '$'), alignment: 'right', bold:true}],
                }
              ]
            }
            return columns;
          })
        ,
        {
          margin: [20, 10, 0, 0],
          columns: [

            {
              text: [
                {text:'TERMS AND CONDITIONS\n\n', bold: true, fontSize:8},
                {text: this.terms_front.value, bold:true, fontSize:4.3},
                {text:'\n\n\nBY SIGNING BELOW, I AGREE TO ALL THE TERMS AND CONDITIONS DESCRIBED ABOVE AND ON THE BACK OF THIS FORM.', fontSize:5, bold:true},
                {text:'', fontSize:6},
              ],
              margin: [-15, 0, 0, 0]

            }
          ]
        },
        {
          margin: [5, 40, 0, 0],
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 200,
              h: 0,
              // lineWidth: 10,
              lineColor: 'black',
            },
            {
              type: 'rect',
              x: 350,
              y: 0,
              w: 140,
              h:0,
              lineColor: 'black'
            }
          ]
        },
        {
          margin: [5, 5 , 0 ,0 ],
          columns: [
            {
              text:"Name"
            },
            {
              margin: [210, 0 , 0 ,0],
              text:"Date"
            }
          ]
        },

        {
          margin: [5, 25, 0, 0],
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 200,
              h: 0,
              // lineWidth: 10,
              lineColor: 'black',
            }

          ]
        },
        {
          margin: [5, 5 , 0 ,0 ],
          columns: [
            {
              text:"Signature"
            },

          ]
        },
      {
        image: !skip? Util.LAPC_IMG : '',
        width: 140,
        height: 110,
        margin: [180, -10, 0, 20]
      },
      {
        margin: [5, 0 , 0 ,0 ],
        columns: [
          {
            text: [{text:this.terms_back.value, fontSize:5, alignment: 'left'}],
            pageBreak: last ? '' : 'after'
          }
        ]
      }
    ]
  }


  print() {
    // tslint:disable-next-line:prefer-const

    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
    const content = JSON.parse(JSON.stringify([...this.order_devices])).reduce((result, current, index) => result.concat(this.getFirstPage(current, index == this.order_devices.length - 1)), [])

    const docDefinition = {
      content
    }

    pdfMake.createPdf(docDefinition).print();
  }

  getTotal(od: OrderDevice) {
    return od.services.sumBy(x => x.price)
  }

  capitilaze(evt, charge) {
    // let text = charge.name;
    // text = text.trim();
    // console.log(text);

    // let text1 = '';
    // for (let i = 0; i < text.length; i++)
    // {
    //   if (i == 0)
    //     text1 = text.substring(0, 1).toUpperCase();
    //   else if (text.substring(i - 1, i) == ' ')
    //   {
    //     if (text.substring(i, i + 1) == ' ') i++;
    //     else text1 += text.substring(i, i + 1).toUpperCase();
    //   }
    //   else
    //   {
    //     text1 += text.substring(i, i + 1).toLowerCase();
    //   }
    // }
    charge.name = charge.name;
  }
 
}