<div class="buttons">
    <div class="actions" style="width: 50%;">

        <button class="btn btn-clear btn-0br no-padding" (click)='open()'>Create new <i class="fas fa-plus red"></i></button>
    </div>
    <!-- <div class="btn-select">
        <button class="btn btn-blue btn-0br" [disabled]='!selectedCustomer' (click)='selectCust()'>Select <i class="fas fa-check"></i></button>
    </div> -->
</div>
<!--Search Bar-->
<div class="search-b searching" id="search-b">
    <input [(ngModel)]='searchTerm' name="search" type="text" (input)="searchCustomer($event)" placeholder="Search..." id="search-input" autocomplete="off">
    <button class="btn btn-clear" style="padding: 0;" (click)="search($event)"><img style="width: 32px; height: 32px;" [src]="'../../../assets/imgs/' + (searchShown ? 'close.png' : 'search-icon.png')" alt=""></button>
</div>

<div class="modal-container" *ngIf='sheetShowed'>
    <div class="modal" id="modal-customer">
        <img style="height: 80px; display: block; margin-left: auto;" src="../../../assets/imgs/close.png" alt="" (click)='close()'>
        <div class="customer-box container">
            <div style="animation: fadeIn 1s forwards;">
                <form #form='ngForm' (ngSubmit)='submit(form)' class="fields">
                    <div class="field w-12">
                        <h2 style="font-size: 25px; margin-bottom: 20px;"> <img style="height: 20px;" src="../../../assets/imgs/user.png" alt=""> {{customer.id == -1 ? 'Add New Price' : 'Add New Price'}}</h2>
                    </div>
                    <div class="field w-6">
                        <label for="name">Name</label>
                        <input type="text" placeholder="User Name" name="name" id="name" [(ngModel)]='customer.name'>

                    </div>
                    <div class="field w-6">
                        <label for="name">Price</label>
                        <input style="text-transform: uppercase;" type="text" placeholder="Price" name="price" id="price" [(ngModel)]='customer.price'>

                    </div>

                    

                    <p  style="margin-bottom: 20px; margin-top: -20px;" class="w-12 invalid-text" [ngStyle]="{'opacity': customer.name.trim().length > 0 ? '0' : '1'}">Name is required</p>
                    <div class="field w-12">
                        <label for="">Brand</label>
                        <app-combobox [value]='customer.model?.brand?.name' [property]='"name"' [_data]='brands' [placeholder]='"Select a brand"' (dataChanged)='brandChanged($event)'></app-combobox>
                    </div>
                    <div class="field w-12">
        
                        <label for="">Model</label>
                        <app-combobox [value]='customer.model?.name' [placeholder]='"Select a model"' [_data]='models' [property]='"name"' [disabled]='customer.model?.brand?.name.trim() == ""' (dataChanged)='modelChanged($event)'></app-combobox>
                    </div>
                   
                    
                    
                    <div class="container btn-container mobile-button">
                        <button type="submit" class="btn btn-big" style=" display: block; margin-left: auto; margin-bottom: 20px;" [disabled]='!isValid()'>SAVE</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<div class="prices-table" id="print-section">
    <div class="header" id="header">
        <p (click)="sort('id')" [ngClass]="{'active': sortBy.name == 'id'}">ID<i *ngIf="sortBy.name == 'id'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p>NAME</p>
        <p>DEVICE</p>
        <p>PRICE</p>
        <p *ngIf="baseService.USER.level >= 9">ACTIONS</p>
    </div>
    <hr>
    <div class="body" style="max-height: 70vh; overflow: scroll;">

        <cdk-virtual-scroll-viewport itemSize="56" style="width: 100%; height: 70vh;">

            <ng-container #table *cdkVirtualFor="let customer of customers; let i = index;">

                <div class="row">
                    <div class="column">
                        <p>{{customer.id}}</p>
                    </div>
                    <div class="column">
                        <p>{{customer.name}}</p>
                        <div class="phone-only">
                            <p style="margin-top: 4px;"> {{customer.model.brand.name}} {{customer.model.name}}</p>
                        </div>
                    </div>

                    <div class="column">
                        {{customer.model.brand.name}} {{customer.model.name}}
                    </div>

                    <div class="column">
                        <p>{{toCurrency(customer.price)}}</p>
                        <div class="phone-only" *ngIf="baseService.USER.level >= 9">
                            <button style="margin-top: 4px;" class="btn btn-red btn-0br p-6" (click)='deleteCustomer(customer)'><i class="fas fa-trash-alt"></i></button>
                        </div>
                    </div>


                    <div class="column" *ngIf="baseService.USER.level >= 9">
                        <button class="btn btn-red btn-0br p-6" (click)='deleteCustomer(customer)'><i class="fas fa-trash-alt"></i></button>
                    </div>
                    

                    <!-- <button class="full-button" [ngClass]="{'active' : isCustSelected(customer)}" (click)="toogleSelected(customer)"></button> -->
                    <!-- <button class="big" (click)='selectCust(customer)'></button> -->

                </div>
                <!-- <button class="btn btn-clear no-padding row-button" style="width: 100%; cursor: pointer;" (click)='selectCust(customer)'></button> -->

            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>