import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NewOrderComponent } from './components/new-order/new-order.component';
import { OpenTicketsComponent } from './components/open-tickets/open-tickets.component';
import { ArchivedTicketsComponent } from './components/archived-tickets/archived-tickets.component';
import { CustomersComponent } from './components/customers/customers.component';
import { LoginComponent } from './components/login/login.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ReceiptComponent } from './components/shared/receipt/receipt.component';
import { OrderFormComponent } from './components/shared/order-form/order-form.component';
import { AuthGuard } from './guards/auth.guard';
import { TimerComponent } from './components/shared/timer/timer.component';
import { UpdateTermsComponent } from './components/shared/update-terms/update-terms.component';
import {UpdateReceiptComponent} from "./components/update-receipt/update-receipt.component";
import {EmployeesComponent} from "./components/employees/employees.component";
import { PricesComponent } from './components/prices/prices.component';


const routes: Routes = [
  {
    path: '',
    component: OpenTicketsComponent
  },
  {
    path: 'open-tickets',
    component: OpenTicketsComponent
  },
  {
    path: 'archived-tickets',
    component: ArchivedTicketsComponent
  },
  {
    path: 'customers',
    component: CustomersComponent
  },
  {
    path: 'new-order',
    component: NewOrderComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'update-terms',
    component: UpdateTermsComponent
  },
  {
    path: 'employees',
    component: EmployeesComponent
  },
  {
    path: 'update-receipt',
    component: UpdateReceiptComponent
  },
  {
    path: 'order-detail/:order_device_id',
    component: OrderDetailComponent
  },
  {
    path: 'receipt/:order_device_id',
    component: ReceiptComponent
  },
  {
    path: 'order-form/:order_device_id',
    component: OrderFormComponent
  },
  {
    path: 'clock/:user_id',
    component: TimerComponent
  },
  {
    path: 'prices',
    component: PricesComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
