<div class="buttons" id="filters-1">
    <div class="buttons-1" id="filter" *ngIf='!isArchived'>
        <button class="btn" id="btn-created-on" (click)="applyFilterClick($event)">All</button>
        <button class="btn btn-outline" id="btn-due-on" (click)="applyFilterClick($event, 1)">Open</button>
        <button class="btn btn-outline" id="btn-due-on" (click)="applyFilterClick($event, 2)">Completed</button>
    </div>

    <input *ngIf="isArchived" type="text" (datesUpdated)='datesChanged($event)' ngxDaterangepickerMd [(ngModel)]="selected" class="form-control dates" opens="center" drops="down" readonly style="font-weight: bold;" />
</div>


<div class="filters" id="filters">
    <div #filter1 class="left" id="left-container">
        <button class="btn btn-big" (click)="applyFilter1Click($event)" id="btn-all" [ngStyle]="{'opacity': searchShown ? '0' : '1'}" *ngIf="items.length > 0">ALL {{getTotalItem()}}</button>
        <button class="btn btn-big btn-clear" (click)="applyFilter1Click($event, item)" *ngFor="let item of items; let i = index;" [ngStyle]="{'opacity': searchShown ? '0' : '1'}">{{item.type}} {{item.count}}</button>
    </div>
    <!--Search Bar-->
    <div class=" right search-b searching" id="search-b">
        <input [(ngModel)]='searchTerm' name="search" type="text" (input)="searchDevice($event)" placeholder="Search" id="search-input" autocomplete="off">
        <button class="btn btn-clear" style="padding: 0;" (click)="search($event)"><img style="width: 32px; height: 32px;" [src]="'../../../assets/imgs/' + (searchShown ? 'close.png' : 'search-icon.png')" alt=""></button>
    </div>
</div>

<div class="buttons" id="filters-1">
    <div class="buttons-1" id="filter" *ngIf='!isArchived'>
        <button class="btn btn-outline" id="btn-created-on" *ngIf="selectedOrderDevices.length == 1" (click)='orderDetail()'>See Details</button>
        <button class="btn btn-outline" id="btn-due-on" *ngIf="isValidToReceipt()" (click)="issueReceipt($event)">Issue Receipt</button>
    </div>
</div>

<div class="device-table">
    <div class="header" id="header">
        <p (click)="sort('id')" [ngClass]="{'active': sortBy.name == 'id'}">ID <i *ngIf="sortBy.name == 'id'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p>DEVICE</p>
        <p>SERVICES</p>
        <p (click)="sort('due')" [ngClass]="{'active': sortBy.name == 'due'}">DUE ON <i *ngIf="sortBy.name == 'due'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p (click)="sort('created')" [ngClass]="{'active': sortBy.name == 'created'}">CREATED ON <i *ngIf="sortBy.name == 'created'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p>TECHNICIAN</p>
        <p>CUSTOMER</p>
        <p (click)="sort('order')" [ngClass]="{'active': sortBy.name == 'order'}">ORDER # <i *ngIf="sortBy.name == 'order'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
        <p (click)="sort('status')" [ngClass]="{'active': sortBy.name == 'status'}">STATUS <i *ngIf="sortBy.name == 'status'" class="fas fa-long-arrow-alt-{{iconSide()}}"></i></p>
    </div>
    <hr>
    <div class="body" style="max-height: 70vh; overflow: scroll;">

        <cdk-virtual-scroll-viewport itemSize="62" style="width: 100%; height: 70vh;">

            <ng-container #table *cdkVirtualFor="let od of orders_device; let i = index;">

                <div class="row" [ngStyle]="{'border-right-color': util.getColorByStatus(od.status)}">
                    <div class="column">
                        <p>{{od.id}}</p>
                    </div>
                    <div class="column" (click)='orderDetail()' style="cursor: pointer;">
                        <p>{{od.device.model.brand.name}} {{od.device.model.name}}</p>
                        <p>{{od.device.type}}</p>
                    </div>
                    <div class="column">
                        <p>{{od.services.length > 0 ? od.services[0].name : ''}}</p>
                        <p *ngIf="od.services.length > 1">+ {{od.services.length - 1 }} more</p>
                    </div>
                    <div class="column">
                        <p [ngStyle]="{'color': getDueColor(od.time_due)}">{{od.time_due.split(" ")[0] | date: 'M-d-yy'}}</p>
                        <p>{{getDiffTime(od.time_due)}}</p>
                    </div>
                    <div class="column">
                        <p>{{od.order.time.split(" ")[0] | date: 'M-d-yy'}}</p>
                    </div>
                    <div class="column">
                        <p>{{od.order.user.name}}</p>
                    </div>
                    <div class="column">
                        <p>{{od.order.customer.name}}</p>
                        <p>{{od.order.customer.phone1}}</p>
                    </div>
                    <div class="column">
                        <p>{{od.order.id}}</p>
                    </div>
                    <div class="column">
                        <button class="btn btn-normal" [ngStyle]="{'background': util.getColorByStatus(od.status)}">{{od.status}}</button>
                    </div>
                    <div class="column" *ngIf="!isArchived">
                        <input type="checkbox" [checked]='isOdSelected(od)'>
                    </div>
                    <button class="full-button" [ngClass]="{'active' : isOdSelected(od)}" (click)="toogleSelected(od)"></button>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>